
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import ActionButton from 'components/common/ActionButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Form, FormControl, InputGroup, ListGroup, Modal, Row, Spinner, Table, ToggleButton } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
//import { toast, ToastContainer } from 'react-toastify';
import IconAlert from 'components/common/IconAlert';
import DatePicker from "react-datepicker";

import SoftBadge from 'components/common/SoftBadge';
import ModalDatosFiscalesIncompletos from 'akasia-components/cliente/ModalDatosFiscalesIncompletos';


const NuevaOrden = () => {
    //AUTENTICACION
    const { token, setToken, idUsuario, usuario, nombreCompleto } = useToken();

    const [cartItems, setCartItems] = useState([]);
    const [productosLista, setProductosLista] = useState([]);
    const [formaPago, setFormaPago] = useState([]);
    const [cliente, setCliente] = useState([]);

    const [showModalProductos, setShowModalProductos] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [fechaLimitePago, setFechaLimitePago] = useState(false);
    const [confirmandoOrden, setConfirmandoOrden] = useState(false);

    const [seguroStateGuardar, setSeguroStateGuardar] = useState(false);
    const [stateSeguroEliminarOrdenDetalle, setstateSeguroEliminarOrdenDetalle] = useState(false);

    const [showModalDatosFiscalesIncompletos, setShowModalDatosFiscalesIncompletos] = useState(false);

    const navigate = useNavigate();

    const [totales, setTotales] = useState({
        idOrden: 0,
        idUsuario: idUsuario,
        idClienteAkasia: idClienteAkasia,
        idCatFormaPago: "",
        vMoneda: "",

        dFechaVenta: (new Date() + ""),
        dFechaVencimiento: (new Date() + ""),
        dSubtotal: 0.00,
        dDescuento: 0.00,
        dIVA: 0.00,
        dTotal: 0.00,
        dTipoCambio: 1.0,
        bIsPagada: false,
        bIsRecompra: false,
        bIsFacturado: false,
        vCreatedUser: usuario
    });

    const { idClienteAkasia, idOrden } = useParams();

    const [bIsNuevaOrden, setBIsNuevaOrden] = useState(true);

    //Columnas Para Los Clientes Que se Cambiar�n
    const columns = [
        {
            accessor: 'none',
            Header: '',
            Cell: rowData => {
                const { idProducto, vCodigoBarras, vNombreProducto, dPrecioUnitario, dIVA, dTotal } = rowData.row.original;
                return (
                    <Row className="flex-end-center">
                        <Col xs="auto" >
                            <Button variant="primary" size="sm" onClick={() => {

                                var obj = cartItems.concat({
                                    index: cartItems.length+1,
                                    idProducto: idProducto,
                                    vCodigoBarras: vCodigoBarras,
                                    vNombreProducto: vNombreProducto,
                                    dPrecioUnitario: dPrecioUnitario,
                                    dCantidad: 1,
                                    dImporte: dPrecioUnitario,
                                    dDescuento: 0,
                                    dIVA: dIVA,
                                    dTotal: dTotal,
                                    vCreatedUser: usuario,
                                    vClaveUnidad: 'H87',
                                    bEdit: false
                                });
                                setCartItems(obj);
                                calculoTotales(obj);
                                setShowModalProductos(false);

                            }}>
                                +
                            </Button>
                        </Col>
                    </Row>
                );
            }
        },
        {
            accessor: 'idProducto',
            Header: 'ID',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { idProducto } = rowData.row.original;
                return (<b>{idProducto}</b>)
            }
        },
        {
            accessor: 'vCodigoBarras',
            Header: 'Codigo Barras',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vCodigoBarras } = rowData.row.original;
                return <span>{vCodigoBarras}</span>
            }
        },
        {
            accessor: 'vNombreProducto',
            Header: 'Nombre del producto',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vNombreProducto } = rowData.row.original;
                return <span title={vNombreProducto} className="d-inline-block text-truncate" style={{ maxWidth: '300px' }}>{vNombreProducto}</span>
            }
        },

        {
            accessor: 'dTotal',
            Header: 'Total',
            cellProps: { className: 'ps-1 text-end' },
            Cell: rowData => {
                const { dTotal } = rowData.row.original;
                return <code>${parseFloat(dTotal).toLocaleString("es-MX", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                })}</code>
            }
        }
    ];

    //Obtener Lista Productos
    const obtenerListaProductos = async (idClienteAkasia_) => {

        const response = await fetch("/api/orden/ObtenerDatosNuevaOrden/?idClienteAkasia=" + idClienteAkasia_, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);

            setFormaPago(data['table1']);
            setProductosLista(data['table2']);
            setCliente(data['table3'][0]);


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Obtener Si es Una Compra Nueva o Una Recompra
    const obtenerIsRecompra = async (idClienteAkasia_) => {

        var _idOrden = 0;

        _idOrden = idOrden == null ? 0 : idOrden;

        const response = await fetch("/api/orden/ObtenerIsRecompra/?idClienteAkasia=" + idClienteAkasia_ + "&idOrden=" + _idOrden, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            const data = await response.json();
            console.log(data);

            //setStateIsRecompra(data[0].bIsRecompra);

            if (data[0].bIsRecompra === 1) {
                setTotales({ ...totales, bIsRecompra: true });
            }
            else {
                setTotales({ ...totales, bIsRecompra: false });
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };


    /**
     * Registra una orden
     */
    const RegistrarOrden = async () => {

        //let nombreCompleto = "";

        totales.dFechaVencimiento = new Date(totales.dFechaVencimiento).toLocaleString('sv', { timeZoneName: 'short' }).substring(0, 10);

        totales.dFechaVenta = new Date(totales.dFechaVenta).toLocaleString('sv', { timeZoneName: 'short' }).substring(0, 10);

        var objNuevaOrden = {};

        objNuevaOrden.mHeader = totales;
        objNuevaOrden.mListDetails = cartItems;

        var jsonOrden = JSON.stringify(objNuevaOrden);
        console.log("=============================Registrar Una Orden=============================");
        console.log(jsonOrden);
        console.log(objNuevaOrden);

        setConfirmandoOrden(true);

        const response = await fetch("/api/orden/GuardarOrden_Body/", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: jsonOrden + "",
        }).catch((error) => {

            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                if (!bIsNuevaOrden) {//validacion para asegurar que solo envie notificacion cuando realmente se este creando una nueva orden
                    var objToActionString = crearObjAction(2, "/ordenes/detalle/" + data.detalles, '', '');
                    guardarNotificacion("Nueva orden creada", "<strong>" + nombreCompleto + "</strong> creo una nueva orden <strong>#" + data.detalles + "</strong>", 0, objToActionString, 4);

                }
                setConfirmandoOrden(false);
                navigate("/ordenes/detalle/" + data.detalles);

            } else {
                setConfirmandoOrden(false);
                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }




        }
        else {

            setConfirmandoOrden(false);

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);


            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * Validar campos
     * @returns
     */
    const ValidarInformacionOrden = () => {

        /**
         * Se valida que este seleccionada una forma de PAGO
         * */
        if (totales.idCatFormaPago == "") {

            setMensajeError("Para continuar debes seleccionar una forma de pago");
            setShowMensajeError(true);
            return;
        }

        /**
         * Se valida que este seleccionada una moneda
         */
        if (totales.vMoneda == "") {

            setMensajeError("Para continuar debes seleccionar una Moneda");
            setShowMensajeError(true);

            return;
        }

        /**
         * Valida que este seleccionada una fecha de la venta
         */
        if (totales.dFechaVenta == "") {
            setMensajeError("Para continuar debes seleccionar una fecha de la venta");
            setShowMensajeError(true);

            return;
        }

        if (cartItems == null) {
            setMensajeError("Para continuar debes agregar al menos 1 producto a la orden");
            setShowMensajeError(true);

            return;
        }

        if (cartItems.length == 0) {
            setMensajeError("Para continuar debes agregar al menos 1 producto a la orden");
            setShowMensajeError(true);

            return;
        }

        setConfirmandoOrden(true);
        RegistrarOrden();

    }

    /**
     * validar si se elijio por definir
     * @param {any} idCatFormaPago
     */
    const validarCreditoCliente = (idCatFormaPago) => {
        if (idCatFormaPago == 22)
            setFechaLimitePago(true)
        else
            setFechaLimitePago(false)
    }

    /**
     * calcular Datos cuando agrega producto
     * @param {any} cartItems
     */
    const calculoTotales = (cartItems) => {
        var _dTotal = 0;
        var _subtotal = 0;
        var _iva = 0;


        cartItems.map((p) => {
            _dTotal = _dTotal + p.dTotal;
            _subtotal = _dTotal / 1.16;
            _iva = _subtotal * 0.16;
        })

        setTotales(
            {
                idOrden: totales.idOrden,

                dSubtotal: _subtotal,
                dDescuento: 0.00,
                dIVA: _iva,
                dTotal: _dTotal,
                idCatFormaPago: totales.idCatFormaPago,
                idClienteAkasia: idClienteAkasia,
                idUsuario: idUsuario,
                vMoneda: totales.vMoneda,

                dFechaVenta: new Date(totales.dFechaVenta) + "",
                dFechaVencimiento: new Date(totales.dFechaVencimiento) + "",
                dTipoCambio: totales.dTipoCambio,
                bIsRecompra: totales.bIsRecompra,
                bIsPagada: false,
                bIsFacturado: false,
                vCreatedUser: usuario
            }
        )
    }

    /**
     * Obtiene el tipo de cambio de BANXICO
     */
    const obtenerBanxicoTC = async (dateParam_) => {

        console.log(dateParam_);
        console.log(totales.dFechaVenta);

        var date_ = (dateParam_ == '') ? new Date(totales.dFechaVenta).toLocaleString('sv', { timeZoneName: 'short' }).substring(0, 10) : new Date(dateParam_).toLocaleString('sv', { timeZoneName: 'short' }).substring(0, 10);

        if (dateParam_ == '') {
            dateParam_ = totales.dFechaVenta;
        }

        console.log(date_);

        setConfirmandoOrden(true);

        const response = await fetch("https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF60653/datos/" + date_ + "/" + date_ + "?decimales=sinCeros&token=7d0140dc6159dbe4a870c61dce8136190da8e9757776cb679483bdaaa31643e6", {
            method: 'GET'
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            console.log(data.bmx.series[0].datos[0].dato);

            //setTotales({ ...totales, dTipoCambio: data.bmx.series[0].datos[0].dato });

            setTotales({ ...totales, vMoneda: "USD", dTipoCambio: data.bmx.series[0].datos[0].dato, dFechaVenta: new Date(dateParam_) + "" });
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

        setConfirmandoOrden(false);
    }

    /**
     * Obtiene los detalles de la orden
     */
    const obtenerInfoOrden = async () => {

        const response = await fetch("/api/orden/obtenerDetallesDelaOrden/?idOrden=" + idOrden,
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {
                const data = JSON.parse(responseType.detalles);

                console.log("===================== Obtener Detalles Orden =========================");
                console.log(data);
                console.log(data.table2);
                console.log(data.table3);

                setTotales(data.table2[0]);
                setCartItems(data.table3);

                setBIsNuevaOrden(false);

                console.log("===================== Obtener Detalles Orden =========================");

            } else {
                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);

                //console.log(response);
            }

        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
     * Valida que todos los datos fiscales esten llenos antes de crear una orden de compra
     */
    const validarDatosFiscales = () => {
        var datosCompletos = true;

        if (cliente.vRfc != null) {
            if (cliente.vRfc == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (cliente.vRazonSocial != null) {
            if (cliente.vRazonSocial == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (cliente.vCodigoRegimen != null) {
            if (cliente.vCodigoRegimen == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (cliente.vDireccionFiscal != null) {
            if (cliente.vDireccionFiscal == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (cliente.vCodigoUso != null) {
            if (cliente.vCodigoUso == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (cliente.vCp != null) {
            if (cliente.vCp == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        //if (!cliente.vTelefonoPersonal.includes("+52")) { //si el cliente es de otro pais el vNumRegTrib no puede estar vacio

        //    if (cliente.vNumRegTrib != null) {
        //        if (cliente.vNumRegTrib == "")
        //            datosCompletos = false
        //    } else {
        //        datosCompletos = false
        //    }
        //}

        if (datosCompletos) {
            setSeguroStateGuardar(true);
        }
        else {
            setShowModalDatosFiscalesIncompletos(true);
        }
    }

    /**
     * Crea un objeto de acci�n que especifica la acci�n a realizar al hacer clic en una notificaci�n,
     * ya sea abrir un modal o redireccionar a otra p�gina.
     * @param {any} TypeAction
     * @param {any} vURL
     * @param {any} idModalType
     * @param {any} idLead
     * @returns
     */
    const crearObjAction = (TypeAction, vURL, idModalType, idLead,) => {
        var objToAction = {};
        var objDetalles = {};
        var objData = {};

        objData.idLead = idLead;

        objDetalles.vUrl = vURL;
        objDetalles.idModalType = idModalType;
        objDetalles.data = objData

        objToAction.TypeAction = TypeAction;
        objToAction.detalles = objDetalles;

        var objToActionString = JSON.stringify(objToAction);
        console.log("objToAction de la Orden: ", objToActionString);
        return objToActionString;
    };

    /**
     * Guarda una notificaci�n
     */
    const guardarNotificacion = async (vTitulo, vCuerpo, idUsuarioTarget, objToAction, idTipoNotificacion) => {

        var jDataSend = {};
        jDataSend.vTitulo = vTitulo;
        jDataSend.vCuerpo = vCuerpo;
        jDataSend.idUsuarioTarget = idUsuarioTarget;
        jDataSend.objToAction = objToAction;
        jDataSend.idTipoNotificacion = idTipoNotificacion;

        const response = await fetch("/api/leads/guardarNotificacion/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACI�N GUARDADA ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);
            console.log("objToAction: ", objToAction);
            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                var notificacionesList = detalles.table2;
                console.log("notificacionesList: ", notificacionesList);

                notificacionesList.map((objNotify) => {
                    sendPushNotificationRealTime(objNotify.vToken, objNotify.vCuerpo);
                })


            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
        }
    };

    /**
     * Envia una notificacion push al usuario con el token que recibe como parametro
     * @param {any} token
     * @param {any} title
     * @param {any} body
     */
    const sendPushNotificationRealTime = async (vToken, vNotificacion) => {

        const responseTokenFCM = await fetch("/api/account/generateToken/",
        {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {

            console.log('Hubo un problema con la peticion Fetch del token:' + error);
        });


        //console.log("TOKEN TOKEN:", token);
        console.log("responseTokenFCM:", responseTokenFCM);

        if (responseTokenFCM.ok) {
            const dataFCM = await responseTokenFCM.json();
            console.log("data", dataFCM);

            var tokenFCM = dataFCM.detalles;

            try {
                const response = await fetch('https://fcm.googleapis.com/v1/projects/akasia-admin-panel/messages:send', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + tokenFCM,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        message: {
                            token: vToken,
                            data: { vNotificacion: vNotificacion }
                        }
                    })
                });

                const dataNtf = await response.json();
                console.log('Notificaci�n enviada:', dataNtf);

            } catch (error) {
                console.error('Error al enviar la notificaci�n:', error.message);
            }
        }

        //POST 
        //https://fcm.googleapis.com/fcm/send

    };

    /**
     * Se ejecuta por primera vez
     */
    useEffect(() => {


        obtenerListaProductos(idClienteAkasia);

        if (idOrden == null)
            obtenerIsRecompra(idClienteAkasia);

        if (idOrden != null)
            obtenerInfoOrden();



    }, []);

    return (
        <>

            <Row className="g-3">
                <Col xl={8}>
                    <Card className="mb-3">
                        <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
                            <h5 className="mb-0">Productos a vender</h5>
                            <IconButton
                                iconClassName="me-2"
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-2"
                                onClick={() => setShowModalProductos(true)}
                            >
                                Agregar producto
                            </IconButton>
                        </Card.Header>
                        <Card.Body>
                            <Table borderless className="fs--1 mb-0">
                                <tbody>
                                    {cartItems?.map((product, index) => (
                                        <tr key={index} className="border-bottom">
                                            <th
                                                className={classNames('ps-0', {
                                                    'pt-0': index === 0
                                                })}
                                            >
                                                <Row>
                                                    <Col xl={1} lg={1} md={1} sm={1} xs={1} xxs={1}>
                                                        <Link to="#" onClick={() => {

                                                            var obj = cartItems.filter(a =>
                                                                a.index !== product.index
                                                            );

                                                            setCartItems(obj);
                                                            calculoTotales(obj);
                                                        }}>
                                                            <img src="/img/cancelar30.png"></img>
                                                        </Link>
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={2} xs={4} xxs={3}>
                                                        {product.vCodigoBarras == 'LIC-SERV' &&
                                                            <img src="/img/servidor48.png" width="45"></img>}
                                                        {product.vCodigoBarras == 'LIC-CAJ' &&
                                                            <img src="/img/caja48.png" width="45"></img>}
                                                        {product.vCodigoBarras == 'LIC-NUB' &&
                                                            <img src="/img/nube48.png" width="45"></img>}
                                                    </Col>
                                                    <Col >
                                                        {product.vNombreProducto}
                                                        <div className="text-400 fw-normal fs--2">
                                                            {product.dCantidad} X {product.vCodigoBarras} &nbsp;&nbsp;&nbsp;&nbsp;UNIDAD: {product.vClaveUnidad}&nbsp;&nbsp;&nbsp;IVA: {product.dIVA.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                            <br />
                                                            Precio Unitario (IVA Incluido) {(product.dPrecioUnitario * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </th>
                                            <th className="align-middle">
                                                <Flex direction='row-reverse'>
                                                    {!product.bEdit ?
                                                        <>
                                                            <ListGroup horizontal className={(product.dTotal == 0) && "border-danger border"}>
                                                                <ListGroup.Item onDoubleClick={() => setCartItems(
                                                                    cartItems.map(a => {
                                                                        if (a.index === product.index) {
                                                                            // Crea un *nuevo* objeto con cambios
                                                                            return { ...a, bEdit: true };
                                                                        } else {
                                                                            // No cambia
                                                                            return a;
                                                                        }
                                                                    })
                                                                )}>{product.dTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <ActionButton onClick={() => setCartItems(
                                                                        cartItems.map(a => {
                                                                            //console.log("id producto en el carrito (a.index): ", a.index);
                                                                            //console.log("id producto a editar(product.index): ",product.index);
                                                                            if (a.index === product.index) {
                                                                                // Crea un *nuevo* objeto con cambios
                                                                                return { ...a, bEdit: true };
                                                                            } else {
                                                                                // No cambia
                                                                                return a;
                                                                            }
                                                                        })
                                                                    )} icon="edit" title="Editar" variant="action" className="p-0 me-2" />
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        </>
                                                        :
                                                        <InputGroup className="fs--1">

                                                            <FormControl type="number" size="sm" className="text-center" id={'dCantidad' + index} defaultValue={product.dCantidad} />
                                                            &nbsp;&nbsp;
                                                            <div className="align-self-center fw-bold">
                                                                <span> X </span>
                                                            </div>
                                                            &nbsp;&nbsp;
                                                            <InputGroup.Text>$</InputGroup.Text>
                                                            <FormControl type="number" size="sm" className="text-center" id={'dPrecioUnitarioIVA' + index} defaultValue={(product.dPrecioUnitario * 1.16).toFixed(2)} />

                                                            <Button onClick={() => {
                                                                var obj = cartItems.map(a => {
                                                                    if (a.index === product.index) {
                                                                        // Crea un *nuevo* objeto con cambios
                                                                        var _dTotal = 0, _dSubtotal = 0, _dIVA = 0, _dCantidad = 0, _dPrecioUnitarioIVA = 0, _dPrecioUnitario;

                                                                        if (
                                                                            Number.isNaN(Number.parseFloat(document.getElementById('dPrecioUnitarioIVA' + index).value)) ||
                                                                            Number.isNaN(Number.parseFloat(document.getElementById('dCantidad' + index).value))
                                                                        ) {
                                                                            _dTotal = 0;
                                                                            _dIVA = 0;
                                                                            _dSubtotal = 0;
                                                                            _dCantidad = 0;
                                                                            _dPrecioUnitarioIVA = 0;
                                                                        }
                                                                        else {
                                                                            _dPrecioUnitarioIVA = Number.parseFloat(document.getElementById('dPrecioUnitarioIVA' + index).value);
                                                                            _dCantidad = Number.parseInt(document.getElementById('dCantidad' + index).value);

                                                                            _dTotal = _dPrecioUnitarioIVA * _dCantidad;

                                                                            _dSubtotal = _dTotal / 1.16;
                                                                            _dIVA = _dSubtotal * 0.16;

                                                                            _dPrecioUnitario = _dPrecioUnitarioIVA / 1.16;
                                                                        }

                                                                        return { ...a, bEdit: false, dTotal: _dTotal, dIVA: _dIVA, dPrecioUnitario: _dPrecioUnitario, dImporte: _dSubtotal, dCantidad: _dCantidad };
                                                                    } else {
                                                                        // No cambia
                                                                        return a;
                                                                    }
                                                                });
                                                                setCartItems(obj);
                                                                calculoTotales(obj);
                                                            }
                                                            } variant="outline-secondary" id="button-addon2">
                                                                <img src="/img/check25.png"></img>
                                                            </Button>


                                                        </InputGroup>}
                                                </Flex>
                                            </th>
                                        </tr>
                                    ))}

                                    <tr className="border-bottom">
                                        <th className="ps-0">Subtotal</th>
                                        <th className="pe-0 text-end">${totales.dSubtotal.toFixed(2)}</th>
                                    </tr>

                                    <tr>
                                        <th className="ps-0 pb-0">I.V.A. (Para el Gobierno de M&eacute;xico)</th>
                                        <th className="pe-0 text-end pb-0">${totales.dIVA.toFixed(2)}</th>
                                    </tr>

                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between bg-light">
                            <div className="fw-semi-bold">Total a pagar</div>
                            <div className="fw-bold">{totales.vMoneda} ${totales.dTotal.toFixed(2)} </div>
                        </Card.Footer>
                    </Card>

                    <Card className="mb-3">
                        <Card.Header className="bg-light">

                            <Row>

                                <Col md={12} className="mb-3 mb-md-0">
                                    <Form.Check
                                        type="radio"
                                        className="mb-0 form-check radio-select"
                                    >
                                        <Form.Check.Input
                                            type="radio"
                                            name="clientAddress"
                                            defaultChecked={true}
                                        />
                                        <Form.Check.Label className="mb-0 fw-bold d-block">

                                            {idClienteAkasia} - {cliente.vNombre} {cliente.vApellidos} -


                                            <SoftBadge
                                                pill
                                                bg={totales.bIsRecompra ? 'primary' : 'success'}
                                                className="fs--1 ms-2"
                                            >
                                                <span className="mb-0">
                                                    {totales.bIsRecompra && 'Re-compra'}
                                                    {!totales.bIsRecompra && 'Primer Compra'}
                                                </span>

                                                <FontAwesomeIcon
                                                    icon={classNames({
                                                        check: true
                                                    })}
                                                    transform="shrink-2"
                                                    className="ms-1"
                                                />

                                            </SoftBadge>

                                            <span className="radio-select-content">
                                                <span>
                                                    {cliente.vRfc} - {cliente.vRazonSocial}<br></br>{cliente.vDireccionFiscal}. {' '}
                                                    {cliente.vEstadoDepProv}, {cliente.vPais}.
                                                    <span className="d-block mb-0 pt-2">{cliente.vTelefonoPersonal} {cliente.vTelefonoTrabajo}</span>
                                                    <span className="d-block mb-0 pt-2">{cliente.vEmail}</span>
                                                    <span className="d-block mb-0 pt-2">{cliente.vCodigoRegimen} {cliente.vDescripcionRegimenFiscal}</span>
                                                    <span className="d-block mb-0 pt-2">{cliente.vCodigoUso} {cliente.vDescripcionUsoCFDI}</span>
                                                </span>
                                            </span>
                                        </Form.Check.Label>

                                    </Form.Check>
                                </Col>

                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={{ span: 4 }}>
                    <Card>
                        <Card.Header className="bg-light">
                            <h5 className="mb-0">Detalle</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col
                                    md={12}
                                    xl={12}
                                    xxl={12}
                                    className="text-center text-md-center text-xl-center text-xxl-center"
                                >
                                    <ButtonGroup>
                                        <ToggleButton
                                            type="radio"
                                            id="radio-1"
                                            variant={'outline-primary'}
                                            name="radio"
                                            value="USD"
                                            checked={totales.vMoneda === 'USD'}
                                            onChange={() => {

                                                obtenerBanxicoTC("");
                                            }}
                                        >
                                            USD
                                        </ToggleButton>
                                        <ToggleButton
                                            type="radio"
                                            id="radio-2"
                                            variant={'outline-primary'}
                                            name="radio"
                                            value="MXN"
                                            checked={totales.vMoneda === 'MXN'}
                                            onChange={(e) => {
                                                setTotales({ ...totales, vMoneda: e.currentTarget.value, dTipoCambio: 1.0000 })
                                                //setTotales({ ...totales, dTipoCambio: 1.00 })
                                            }}
                                        >
                                            MXN
                                        </ToggleButton>

                                    </ButtonGroup>
                                    <div className="border-dashed border-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>
                                    <div className="fs-2 fw-semi-bold">
                                        Total a pagar:
                                    </div>
                                    <div className="fs-2 fw-semi-bold">
                                        <span className="text-primary">
                                            {totales.dTotal == 0 ? ("----") : ((totales.dTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })) + " " + totales.vMoneda)}
                                        </span>
                                    </div>
                                    {totales.dTipoCambio > 1 &&
                                        <div className="fs--2">
                                            <span className="text-muted fw-bold">
                                                {"T.C. " + totales.dTipoCambio + "  |  " + new Date(totales.dFechaVenta).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}
                                            </span>
                                        </div>
                                    }



                                    <Form.Select className="mb-3 mt-5"
                                        onChange={(e) => { setTotales({ ...totales, idCatFormaPago: e.target.value }); validarCreditoCliente(e.target.value) }}
                                        value={totales.idCatFormaPago}
                                    >
                                        <option value="">Selecciona forma de pago</option>
                                        {formaPago.map((fp, index) => {
                                            return <option key={index} value={fp.idCatFormaPago}>{fp.vDescripcionFormaPago}</option>
                                        })}
                                    </Form.Select>

                                    <Col className="col-12">

                                        <DatePicker
                                            selected={new Date(totales.dFechaVenta)}
                                            onChange={(date) => {
                                                setTotales({ ...totales, dFechaVenta: new Date(date) + "" });

                                                if (totales.vMoneda === 'USD') {
                                                    obtenerBanxicoTC(date);
                                                }
                                            }}
                                            maxDate={new Date()}
                                            dateFormat="dd MMMM yyyy"
                                            className="form-control col-12"
                                            editable="false"
                                        />

                                    </Col>


                                    {fechaLimitePago &&
                                        <>
                                            <IconAlert className="mb-0" variant="warning">
                                                <p className="mb-0">Fecha limite pago</p>
                                            </IconAlert>
                                            <Form.Control
                                                onChange={(e) => setTotales({ ...totales, dFechaVencimiento: e.target.value })}
                                                className="mb-3"
                                                type="date" size="md" />
                                        </>
                                    }
                                    {confirmandoOrden ?

                                        <Button variant="secondary" disabled className="mt-3 px-5 fs--1">
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                className="me-2"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button>
                                        :
                                        <Button
                                            variant="success"
                                            className="mt-3 px-5"
                                            type="submit"
                                            onClick={() => /*setSeguroStateGuardar(true)*/validarDatosFiscales()}
                                        >
                                            Confirmar &oacute;rden
                                        </Button>


                                    }
                                    <p className="fs--1 mt-3 mb-0">
                                        Al hacer clic <strong>confirmas la &oacute;rden </strong> de compra
                                        de acuerdo a los <a href="">T&eacute;rminos &amp; Condiciones de Akasia Software Development, Sa de Cv</a>
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>

            {showModalProductos &&
                <Modal
                    show={showModalProductos}
                    onHide={() => setShowModalProductos(false)}
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    top="top"
                >
                    <Modal.Header>
                        <Modal.Title>Lista de productos y servicios</Modal.Title>
                        <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalProductos(false); }} />
                    </Modal.Header>
                    <Modal.Body style={{ padding: "0px" }}>
                        <AdvanceTableWrapper
                            columns={columns}
                            data={productosLista}
                            sortable
                            pagination
                            perPage={10}
                        >
                            <Card>
                                <Card.Header>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <Row className="flex-end-center mb-3">
                                        <Col sm={8} lg={4}>
                                            <AdvanceTableSearchBox table />
                                        </Col>
                                    </Row>
                                    <AdvanceTable
                                        table
                                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                                        rowClassName="align-middle white-space-nowrap"
                                        tableProps={{
                                            size: 'sm',
                                            striped: true,
                                            hover: true,
                                            className: 'fs--1 mb-0 overflow-hidden'
                                        }}
                                    />

                                </Card.Body>
                                <Card.Footer>

                                    <AdvanceTableFooter
                                        rowCount={productosLista.length}
                                        table
                                        rowInfo
                                        navButtons
                                        rowsPerPageSelection
                                    />
                                </Card.Footer>
                            </Card>

                        </AdvanceTableWrapper>
                    </Modal.Body>

                </Modal>
            }

            {seguroStateGuardar &&
                <Modal
                    show={true}
                    onHide={() => setSeguroStateGuardar(false)}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    top="top"
                >
                    <Modal.Header>
                        <Modal.Title>&#191;Seguro?</Modal.Title>
                        <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setSeguroStateGuardar(false); }} />
                    </Modal.Header>
                    <Modal.Body className="text-dark fs-2">

                        <Row className="mb-3">

                            <Col>
                                &#191; Est&aacute;s <strong>seguro</strong> que deseas guardar esta &oacute;rden {(totales.vMoneda == '' ? <strong className='text-danger'>sin moneda</strong> : <strong className="text-danger">con moneda {totales.vMoneda}</strong>)} ?
                                <br></br>
                                <span className="fs-3 fw-bold"></span>
                            </Col>

                        </Row>


                        <Row>

                            <Col className="col-6">
                                {confirmandoOrden ?
                                    (
                                        <Button variant="secondary" disabled className="col-12 fs--1">
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                className="me-2"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button>
                                    )
                                    :
                                    (
                                        <Button onClick={() => { setstateSeguroEliminarOrdenDetalle(true); }} variant="primary" className="col-12"> SI </Button>
                                    )
                                }
                            </Col>


                            <Col className="col-6">
                                {confirmandoOrden ?
                                    <Button variant="secondary" disabled className="col-12 fs--1">
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            className="me-2"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button>
                                    :
                                    <Button onClick={() => { setSeguroStateGuardar(false); }} variant="danger" className="col-12"> NO </Button>
                                }
                            </Col>

                        </Row>


                    </Modal.Body>

                    <Modal.Footer>



                    </Modal.Footer>

                </Modal>

            }

            {/*Modal para confirmar la eliminacion de la licencia en la orden detalle*/}
            {stateSeguroEliminarOrdenDetalle &&
                <Modal
                    show={true}
                    onHide={() => setstateSeguroEliminarOrdenDetalle(false)}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    top="top"
                >
                    <Modal.Header>
                        <Modal.Title>&#191;Seguro?</Modal.Title>
                        <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setstateSeguroEliminarOrdenDetalle(false); }} />
                    </Modal.Header>
                    <Modal.Body className="text-dark fs-2">

                        <Row className="mb-3">

                            <Col>
                                Todas las licencias asignadas al detalle de la orden se <strong>desasociaran</strong> si deseas continuar.
                            </Col>

                        </Row>


                        <Row>

                            <Col className="col-6">
                                {confirmandoOrden ?
                                    (
                                        <Button variant="secondary" disabled className="col-12 fs--1">
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                className="me-2"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button>
                                    )
                                    :
                                    (
                                        <Button onClick={() => { ValidarInformacionOrden(); }} variant="primary" className="col-12"> SI </Button>
                                    )
                                }

                            </Col>

                            <Col className="col-6">
                                {confirmandoOrden ?
                                    (
                                        <Button variant="secondary" disabled className="col-12 fs--1">
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                className="me-2"
                                                aria-hidden="true"
                                            />
                                            Procesando...
                                        </Button>
                                    )
                                    :
                                    (
                                        <Button onClick={() => { setstateSeguroEliminarOrdenDetalle(false); }} variant="danger" className="col-12"> NO </Button>
                                    )
                                }

                            </Col>

                        </Row>


                    </Modal.Body>

                    <Modal.Footer>



                    </Modal.Footer>

                </Modal>
            }

            {/* Modal para evitar crear ordenes a clientes con datos fiscales faltantes*/

                <ModalDatosFiscalesIncompletos
                    showModalDatosFiscalesIncompletos={showModalDatosFiscalesIncompletos}
                    setShowModalDatosFiscalesIncompletos={setShowModalDatosFiscalesIncompletos}
                />

            }
        </>
    );
};

export default NuevaOrden;
