import ecomBg from 'assets/img/illustrations/ecommerce-bg.png';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';

import useToken from 'hooks/useToken';
import { Card } from 'react-bootstrap';

import Notification from './Notificacion';

const TarjetaSaludo = ({
    dTotalVentaMensual,
    vEmailIncorrecto,
    vDireccionIncompleta,
    vUltimos48Horas,
    vVendedorNoAsignado,
    vTelefonoIncorrecto,
    vLatLonIncorrecto,
    vHorasSoporteTecnico,
    vClientesDuplicados }) => {
    const { name } = useToken();
    return (
        <>
            <Card className="bg-transparent-50 overflow-hidden">
                <Card.Header className="position-relative">
                    <Background
                        image={ecomBg}
                        className="d-none d-md-block bg-card z-index-1"
                        style={{
                            backgroundSize: '230px',
                            backgroundPosition: 'right bottom',
                            zIndex: '-1'
                        }}
                    />
                    <div className="position-relative z-index-2">
                        <div>
                            <h3 className="text-primary mb-1">BUEN D&Iacute;A! {name} </h3>
                            <p className="text-800">
                                <span>Aquí te muestro qué está pasando en la Empresa</span><br></br>
                                <span>Estas estadísticas se basan en tus ventas</span><br></br>
                                <span>y atención al cliente.</span>

                            </p>
                        </div>
                        <Flex className="py-3">
                            <div className="pe-3">
                                <p className="text-600 fs--1 fw-medium">Total de Soporte</p>
                                <h4 className="text-800 mb-0">{(vHorasSoporteTecnico / 60).toFixed(2)} hrs</h4>
                            </div>
                            <div className="ps-3">
                                <p className="text-600 fs--1">Ventas totales</p>
                                <h4 className="text-800 mb-0"> ${parseFloat(dTotalVentaMensual).toLocaleString("es-MX", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}</h4>
                            </div>
                        </Flex>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <ul className="mb-0 list-unstyled">
                        {vEmailIncorrecto > 0 &&
                            <Notification
                                title={"Tienes " + vEmailIncorrecto + " clientes con Email incorrecto."}
                                linkFor={"clientes"}
                                type={"warning"}
                                isLast={false}
                                link="/clientes/informacionincompleta/Email"
                            />
                        }
                        {vClientesDuplicados > 0 &&
                            <Notification
                                title={"Hay " + vClientesDuplicados + " contactos con tel&eacute;fono duplicado. Ayuda a filtrar o eliminar los contactos duplicados."}
                                linkFor={"contactos"}
                                type={"warning"}
                                isLast={false}
                                link="/clientes/contactosduplicados/TelefonoDuplicado"
                            />
                        }
                        {vTelefonoIncorrecto > 0 &&
                            <Notification
                                title={"Tienes " + vTelefonoIncorrecto + " contactos asignados con Tel\u00E9fono incorrecto."}
                                linkFor={"clientes"}
                                type={"warning"}
                                isLast={false}
                                link="/clientes/informacionincompleta/Telefono"
                            />
                        }
                        {vLatLonIncorrecto > 0 &&
                            <Notification
                                title={"Tienes " + vLatLonIncorrecto + " licencias activadas con Coordenadas incorrectas o repetidas. Edita la licencia y coloca las coordendas como corresponde."}
                                linkFor={"licencias"}
                                type={"warning"}
                                isLast={false}
                                link="/licencias/informacionincompleta/Coordenadas"
                            />
                        }
                        {vDireccionIncompleta > 0 &&
                            <Notification
                                title={"Tienes " + vDireccionIncompleta + " clientes con direcci&oacute;n incompleta."}
                                linkFor={"clientes"}
                                type={"warning"}
                                isLast={false}
                                link="/clientes/informacionincompleta/DireccionFiscal"
                            />}
                        {vUltimos48Horas > 0 &&
                            <Notification
                                title={"En las últimas 48 horas se conectaron >>>> <b>" + vUltimos48Horas + "</b> <<<< licencias de prueba asignadas a ti"}
                                linkFor={"licencias"}
                                isLast={false}
                                link="/licencias"
                            />
                        }
                        {vVendedorNoAsignado > 0 &&
                            <Notification
                                title={"Hay " + vVendedorNoAsignado + " licencias de prueba sin vendedor asignado"}
                                linkFor={"licencias"}
                                isLast={false}
                                link="/licencias"
                            />}


                    </ul>
                </Card.Body>
            </Card>

        </>
    );
};

TarjetaSaludo.propTypes = {
    vEmailIncorrecto: PropTypes.number,
    vDireccionIncompleta: PropTypes.number,
    vUltimos48Horas: PropTypes.number,
    vVendedorNoAsignado: PropTypes.number,
    vTelefonoIncorrecto: PropTypes.number,
    vLatLonIncorrecto: PropTypes.number,
    vHorasSoporteTecnico: PropTypes.number,
    vClientesDuplicados: PropTypes.number,
    dTotalVentaMensual: PropTypes.number
};

export default TarjetaSaludo;
