import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';

const NuevaLicenciaCaja = () => {
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { idClienteAkasia } = useParams();

    //DATA
    const [clientes, setClientes] = useState([]);
    const [cicloFacturacion, setCicloFacturacion] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [UUIDEmpresas, setUUIDEmpresas] = useState([]);

    //MENSAJES Y AVISOS

    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [procesandoModal, setProcesandoModal] = useState(false);

    //AUTENTICACION
    const { token, setToken } = useToken();

    //Obtener UUID Empresas De Un Cliente
    const obtenerUUIDEmpresasDeCliente = async (idClienteAkasia) => {
        setUUIDEmpresas([]);
        const response = await fetch("/api/licencia/obtenerUUIDEmpresasDeCliente?idClienteAkasia=" + idClienteAkasia, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setUUIDEmpresas(data);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Obtener UUID
    const ObtenerUUIDRandom = async (action) => {
        const response = await fetch("/api/licencia/ObtenerUUIDRandom", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'text/plain'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.text();
            console.log(data);
            if (action == 'Licencia')
                setValue("vUUIDLicencia", data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };

    //Obtener datos Licencia Nueva
    const obtenerDatosLicenciaNueva = async () => {
        const response = await fetch("/api/licencia/ObtenerDatosLicenciaNueva/?idClienteAkasia=" + (idClienteAkasia ? idClienteAkasia : ""), {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            setClientes(data["table1"]);
            console.log(data["table2"]);
            setVendedores(data["table2"]);
            console.log(data["table3"]);
            setCicloFacturacion(data["table3"]);
            console.log(data["table4"]);
            setValue("vUUIDLicencia", data["table4"][0].vUUIDLicencia);
            if (data["table1"].length == 1)
                obtenerUUIDEmpresasDeCliente(data["table1"][0]["value"])
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

        
    
        setCargando(false);

    };

    //Activar licencia
    const registrarLicencia = async (values) => {
        console.log(values);
        const response = await fetch("/api/licencia/RegistrarLicencia/?values=" + values, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            if (data["table1"][0].iResponseType == 1) {
                setShowMensajeInformativo(true);
                setMensajeInformativo(data["table1"][0].vMensaje);
                navigate("/licencias/detalle/" + data["table2"][0].LastIdentityValue);
            }
            else {
                setShowMensajeError(true);
                setMensajeError(data["table1"][0].vMensaje);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoModal(false);
    };

    const onSubmit = data => {
        setProcesandoModal(true);
        registrarLicencia(JSON.stringify(data));

    };

    //ejecutar solo 1 vez despues del DOM
    useEffect(() => {
        obtenerDatosLicenciaNueva();
    }, []);

    return (
        <>
            <PageHeader
                title={"Registrar Nueva Licencia"}
                className="mb-1"
            >
            </PageHeader>
            <Card>
                <Card.Header className="d-flex flex-between-center">
                    <IconButton
                        onClick={() => navigate(-1)}
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-left"
                    />

                </Card.Header>
            </Card>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-3 mb-3">
                    {cargando && (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)}
                    {!cargando && (
                        <>
                            <Col lg={6}>
                                <Card className="mt-3">
                                    <Card.Header className="border-bottom border-200">
                                        <Row>
                                            <Col xs={2}>
                                                <img src="/img/caja48.png"></img>
                                            </Col>
                                            <Col>
                                                <h4 className="mb-0">POS Caja Adicional</h4>
                                                <p>Considera los siguientes aspectos al registrar esta licencia:</p>
                                                <ul>
                                                    <li>Es necesario que el cliente haya adquirido una licencia POS Servidor.</li>
                                                </ul>
                                            </Col>
                                        </Row>

                                    </Card.Header>
                                    <Card.Body>
                                        <>

                                            <Form.Group className="mb-3">
                                                <Form.Label>UUID Licencia</Form.Label>
                                                <IconButton
                                                    className="ms-2"
                                                    iconClassName="fs--2 me-1"
                                                    variant="falcon-primary"
                                                    size="sm"
                                                    icon="circle"
                                                    onClick={() => { ObtenerUUIDRandom('Licencia') }}
                                                >
                                                    Generar
                                                </IconButton>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete="off"
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                    isInvalid={!!errors.vUUIDLicencia}
                                                    {...register("vUUIDLicencia",
                                                        {
                                                            required: 'El vUUIDLicencia es requerido',
                                                            pattern: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
                                                            maxLength: 36,
                                                            minLength: 36
                                                        })
                                                    }

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vUUIDLicencia && errors.vUUIDLicencia.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>UUID Empresa (Activada)</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.vUUIDEmpresa}
                                                    {...register("vUUIDEmpresa",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    <option value="">Selecciona UUID Empresa</option>
                                                    {UUIDEmpresas.map((em, index) => {
                                                        return (<option key={index} value={em.vUUIDEmpresa}>{em.vUUIDEmpresa}</option>)
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="mt-3">
                                    <Card.Header className="border-bottom border-200">

                                    </Card.Header>
                                    <Card.Body>
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tipo Licencia</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.idCatTipoLicencia}
                                                    {...register("idCatTipoLicencia",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    <option value="2">POS Caja Adicional</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.idCatTipoLicencia && errors.idCatTipoLicencia.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Nombre Vendedor</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.vNombreVendedor}
                                                    {...register("vNombreVendedor",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    <option value="">Selecciona un vendedor</option>
                                                    {vendedores.map((vendedor, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={vendedor.vUsuario}>
                                                                {vendedor.vUsuario} - {vendedor.vNombre} {vendedor.vApellidos}
                                                            </option>)
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Nombre del cliente</Form.Label>
                                                {clientes.length == 1 &&
                                                    <>
                                                        <Form.Control
                                                            type="hidden"
                                                            value={clientes[0]["value"]}
                                                            {...register("idClienteAkasia")}
                                                        />
                                                        <Form.Control
                                                            type="text"
                                                            value={clientes[0]["value"] + " - " + clientes[0]["label"]}
                                                            disabled={true}
                                                        />
                                                    </>

                                                }
                                                {clientes.length > 1 &&
                                                    <>
                                                        <Select
                                                            {...register("idClienteAkasia",
                                                                {
                                                                    required: true
                                                                })
                                                            }
                                                            className="basic-single"
                                                            closeMenuOnSelect={true}
                                                            options={clientes}
                                                            isSearchable={true}
                                                            placeholder='Selecciona...'
                                                            classNamePrefix="react-select"
                                                            onChange={target => { setValue("idClienteAkasia", target.value); obtenerUUIDEmpresasDeCliente(target.value) }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.idClienteAkasia && errors.idClienteAkasia.message}
                                                        </Form.Control.Feedback>
                                                    </>
                                                }
                                            </Form.Group>

                                            <Form.Group className="mb-3" >
                                                <Form.Label>Ciclo Facturaci&oacute;n</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.idCatCicloFacturacion}
                                                    {...register("idCatCicloFacturacion",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    {cicloFacturacion.map((ciclo, index) => {
                                                        return (<option key={index} value={ciclo.idCatCicloFacturacion}>{ciclo.vDescripcionCiclo} - {ciclo.iNumeroDias} d&iacute;as</option>)
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.idCatCicloFacturacion && errors.idCatCicloFacturacion.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Row>
                                                <Col>
                                                    &nbsp;
                                                </Col>
                                                <Col xs="auto">
                                                    {procesandoModal ? <Button
                                                        variant="falcon-default"
                                                        size="sm"
                                                        transform="shrink-3"
                                                        disabled
                                                    >
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Procesando...
                                                    </Button> :
                                                        <IconButton
                                                            className="ms-2"
                                                            iconClassName="fs--2 me-1"
                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="check"
                                                            type="submit"
                                                        >
                                                            Guardar Cambios
                                                        </IconButton>}
                                                </Col>
                                            </Row>

                                        </>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </>
                    )}
                </Row>
            </Form>
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>

            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer/>
        </>
    );
};

export default NuevaLicenciaCaja;