import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner, Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import useToken from 'hooks/useToken';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';
//import Badge from 'react-bootstrap/Badge';


const PlanesFacturacionPeru = () => {

    const columnsRUCs = [
        {
            accessor: 'none',
            Header: '',
            Cell: rowData => {
                const { idPeruFacturacionExistencias } = rowData.row.original;
                return (
                    <>
                        <Row className="flex-start-center">
                            <Col xs="auto" >
                                <Button
                                    variant="light"
                                    size="sm"
                                    className="border-300 text-600"
                                    onClick={() => { reset(); ObtenerRUCByID(idPeruFacturacionExistencias) }}
                                >
                                    <FontAwesomeIcon icon="edit" />
                                </Button>&nbsp;
                                <Button
                                    variant="light"
                                    size="sm"
                                    className="border-300 text-600"
                                    onClick={() => { reset(); ObtenerPlanesDocumentos(idPeruFacturacionExistencias) }}
                                >
                                    <FontAwesomeIcon icon="plus" />
                                </Button>
                            </Col>
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'idPeruFacturacionExistencias',
            Header: 'ID',
            Cell: rowData => {
                const { idPeruFacturacionExistencias } = rowData.row.original;
                return (<span>{idPeruFacturacionExistencias}</span>)
            }
        },
        {
            accessor: 'bIsActive',
            Header: 'Estatus',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { bIsActive } = rowData.row.original;
                return bIsActive ? (<SoftBadge pill bg='success' className='me-2'>Activo</SoftBadge>) : <><SoftBadge pill bg='danger' className='me-2'>No activo</SoftBadge></>;
            }
        },
        {
            accessor: 'vNombre',
            Header: 'Nombre',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { vNombre } = rowData.row.original;
                return (<span >{vNombre}</span>);
            }
        },
        {
            accessor: 'vRUC',
            Header: 'RUC',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vRUC } = rowData.row.original;
                return (<span> {vRUC}</span>)
            }
        },
        {
            accessor: 'vUsuario',
            Header: 'Usuario',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vUsuario } = rowData.row.original;
                return (<span> {vUsuario}</span>)
            }
        },
        {
            accessor: 'vPassword',
            Header: 'Contrasena',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vPassword } = rowData.row.original;
                return (<span> {btoa(vPassword)}</span>)
            }
        },
        {
            accessor: 'iExistencia',
            Header: 'Existencia',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { iExistencia } = rowData.row.original;
                return (iExistencia < 0 ? <SoftBadge pill bg='success' className='me-2'>Ilimitado</SoftBadge> : <span> {(iExistencia == 0 ? <SoftBadge pill bg='danger' className='me-2'>Sin Existencia</SoftBadge> : iExistencia)}</span>)
            }
        },
        {
            accessor: 'dFechaExpiracion',
            Header: 'Fech. Expiraci\u00F3n',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const dFechaExpiracion = rowData.row.original.dFechaExpiracion;
                //const dFaltantes = rowData.row.original.dFaltantes;
                return (dFechaExpiracion == null) ? <SoftBadge pill bg='success' className='me-2' > Sin expiraci&oacute;n</SoftBadge > : <span>{dFechaExpiracion}</span>
            }
        }

    ];
    const { token, setToken } = useToken();
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();

    const [RUCs, setRUCs] = useState([]);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [planesDocumentos, setPlanesDocumentos] = useState([]);

    const [cargando, setCargando] = useState(true);
    const [cargandoDatosParaEditar, setCargandoDatosParaEditar] = useState(true);
    const [showNuevoRuc, setShowNuevoRuc] = useState(false);
    const [showEditarRuc, setShowEditarRuc] = useState(false);
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [showModalAsignarDTE, setShowModalAsignarDTE] = useState(false);

    const [estatus, setEstatus] = useState(false);

    //Obtener UUID
    const ObtenerCadenaRandom = async (action) => {
        const response = await fetch("/api/licencia/ObtenerCadenaRandom", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'text/plain'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.text();
            console.log(data);

            if (action == 'Usuario')
                setValue("vUsuario", data);
            else if (action == 'vPassword')
                setValue("vPassword", data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Solicita los RUCs para mostrar la tabla
    const ObtenerCatalogoRUCs = async () => {
        setCargando(true);
        const response = await fetch("/api/PlanesFacturacionPeru/ObtenerCatalogoRUCs",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                const jsonRucs = JSON.parse(data.detalles); 

                setRUCs(jsonRucs);

            } else {
                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

            setCargando(false);
        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    //Solicita los paquetes de dte
    const ObtenerPlanesDocumentos = async (idRuc) => {
        setShowModalAsignarDTE(true);
        const response = await fetch("/api/PlanesFacturacionPeru/ObtenerPlanesDocumentos",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {
                const data = JSON.parse(responseType.detalles);

                setPlanesDocumentos(data);
                setValue("idRuc", idRuc);

            } else {
                setShowMensajeError(true);
                setMensajeError(responseType.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    //Obtener Informacion de RUC
    const ObtenerRUCByID = async (idRuc) => {
        setShowEditarRuc(true);
        setCargandoDatosParaEditar(true);
        const response = await fetch("/api/PlanesFacturacionPeru/ObtenerRUCByID/?_Id=" + idRuc,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {
                const data = JSON.parse(responseType.detalles);

                console.log(data);

                setValue("idPeruFacturacionExistencias", data.idPeruFacturacionExistencias);
                setValue("vNombre", data.vNombre);
                setValue("vRUC", data.vRUC);
                setValue("vUsuario", data.vUsuario);
                setValue("vPassword", data.vPassword);
                setValue("bIsActive", data.bIsActive);
                setEstatus(data.bIsActive);

            } else {

                setShowMensajeError(true);
                setMensajeError(responseType.vMessage);
                //console.log(response);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargandoDatosParaEditar(false);
    };

    const reset = async () => {
        setValue("idPeruFacturacionExistencias", "0");
        setValue("vRUC", "");
        setValue("vNombre", "");
        setValue("vUsuario", "");
        setValue("vPassword", "");
        setValue("idRuc", "");
        setValue("bIsActive", true);
        setEstatus(true);
    }

    //Registar RUC 
    const GuardarRUC = async (values) => {
        console.log(values);
        const response = await fetch("/api/PlanesFacturacionPeru/GuardarRUC/?jsonRUC=" + encodeURIComponent(values), {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {
                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);

                setShowNuevoRuc(false);
                setShowEditarRuc(false);
                reset();

                ObtenerCatalogoRUCs();
            }
            else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);

            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }

        }


        /* Reiniciando */

    };

    //Asingar Paquete de DTE's' 
    const AsignarPaqueteDTE = async (_idPlan, _idRuc) => {
        setShowModalAsignarDTE(false);
        setPlanesDocumentos([]);
        const response = await fetch("/api/PlanesFacturacionPeru/AsignarPlanARUC/?_idPlan=" + _idPlan + "&_idRuc=" + _idRuc + "", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMessage);

            }
            else {

                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

            ObtenerCatalogoRUCs();
        }
        else {

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);

            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }

        }
    };

    useEffect(() => {
        ObtenerCatalogoRUCs();
    }, []);

    const onSubmitNuevoRuc = data => {

        GuardarRUC(JSON.stringify(data));
        console.log(JSON.stringify(data));

    };

    const onSubmitEditarRuc = data => {
        console.log(JSON.stringify(data));
        GuardarRUC(JSON.stringify(data));
    };

    const onSubmitAsignarDTE = data => {
        AsignarPaqueteDTE(data.idPeruPlanesFacturacion, data.idRuc);
        console.log(JSON.stringify(data));
    };

    const onCheckedChange = data => {
        console.log(data);
        console.log(data.target.checked);
        setEstatus(data.target.checked);
    };

    return (
        <>
            <PageHeader
                title="Documentos Electr&oacute;nicos Fiscales Per&#250;"
                image="/img/bandera-peru.png"
                className="mb-1"
            >
            </PageHeader>

            <AdvanceTableWrapper
                columns={columnsRUCs}
                data={RUCs}
                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <Row className="flex-between-center">
                            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                                <div id="orders-actions">

                                    <IconButton
                                        onClick={() => { reset(); setShowNuevoRuc(true); }}
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3">
                                        <span className="d-none d-sm-inline-block ms-1" >Nuevo RUC</span>
                                    </IconButton>


                                </div>
                            </Col>
                            <Col xs={6} sm="auto" className="ms-auto ps-0">
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                hover: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />

                    </Card.Body>
                    <Card.Footer>

                        <AdvanceTableFooter
                            rowCount={RUCs.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>

            </AdvanceTableWrapper>
            {cargando && (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer/>
            {showNuevoRuc &&
                <Modal
                    show={showNuevoRuc}
                    onHide={() => { setShowNuevoRuc(false); reset() }}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form noValidate onSubmit={handleSubmit(onSubmitNuevoRuc)}>
                        <Modal.Header>
                            <Modal.Title>Registra nuevo RUC</Modal.Title>
                            <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowNuevoRuc(false); reset() }} />

                        </Modal.Header>
                        <Modal.Body>
                            <p>Registra un nuevo RUC, de acuerdo a los lineamientos dictados por la Direcci&#243;n General de Ingresos (DGI).</p>

                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>ESTATUS</Form.Label>
                                        <Row>
                                            <Col md={2}>
                                                <Form.Check // prettier-ignore
                                                    {...register("bIsActive",
                                                        {

                                                        })
                                                    }
                                                    type="switch"
                                                    onChange={e => { onCheckedChange(e) }}

                                                />
                                            </Col>
                                            <Col md={10}>
                                                {estatus ?
                                                    <Col>
                                                        <img src="/img/activo.png"></img> <Badge bg="success">Activo</Badge>
                                                    </Col>
                                                    :
                                                    <Col>
                                                        <img src="/img/noactivo.png"></img> <Badge bg="danger">Desactivado</Badge>
                                                    </Col>
                                                }

                                            </Col>
                                        </Row>


                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>RUC</Form.Label>
                                        <Form.Control

                                            isInvalid={!!errors.vRUC}
                                            {...register("vRUC",
                                                {
                                                    required: 'El RUC es requerido'
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.vRUC && errors.vRUC.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nombre del contribuyente</Form.Label>
                                        <Form.Control
                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            isInvalid={!!errors.vNombre}
                                            {...register("vNombre",
                                                {
                                                    required: 'El Nombre es requerido',
                                                    maxLength: 200,
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.vNombre && errors.vNombre.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Usuario <IconButton
                                            className="ms-2"
                                            iconClassName="fs--2 me-1"
                                            variant="falcon-primary"
                                            size="sm"
                                            icon="circle"
                                            onClick={() => { ObtenerCadenaRandom('Usuario') }}
                                        >
                                            Generar
                                        </IconButton></Form.Label>
                                        <Form.Control
                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            isInvalid={!!errors.vUsuario}
                                            {...register("vUsuario",
                                                {
                                                    required: 'El usuario es requerido',
                                                    maxLength: 40,
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.vUsuario && errors.vUsuario.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Contrase&ntilde;a <IconButton
                                            className="ms-2"
                                            iconClassName="fs--2 me-1"
                                            variant="falcon-primary"
                                            size="sm"
                                            icon="circle"
                                            onClick={() => { ObtenerCadenaRandom('vPassword') }}
                                        >
                                            Generar
                                        </IconButton></Form.Label>
                                        <Form.Control
                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            isInvalid={!!errors.vPassword}
                                            {...register("vPassword",
                                                {
                                                    required: 'La contrase\u00F1a es requerido',
                                                    maxLength: 40,
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.vPassword && errors.vPassword.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => { setShowNuevoRuc(false); reset() }} variant='primary' >Cerrar</Button>
                            <Button type="submit" variant='success' >Registrar</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
            {showEditarRuc &&
                <Modal
                    show={showEditarRuc}
                    onHide={() => setShowEditarRuc(false)}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form noValidate onSubmit={handleSubmit(onSubmitEditarRuc)}>
                        <Modal.Header>
                            <Modal.Title>Editar RUC</Modal.Title>
                            <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => setShowEditarRuc(false)} />

                        </Modal.Header>
                        <Modal.Body>
                            <p>En este formulario es posible editar los datos vinculados al RUC, sin embargo, el RUC no podr&aacute; ser editado.</p>
                            {cargandoDatosParaEditar ?
                                <span><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</span> :
                                <Row>
                                    <Col md={12}>
                                        <Form.Control
                                            type="hidden"
                                            isInvalid={!!errors.idPeruFacturacionExistencias}
                                            {...register("idPeruFacturacionExistencias")
                                            }
                                        />

                                        <Form.Group className="mb-3">
                                            <Form.Label>ESTATUS</Form.Label>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Check // prettier-ignore
                                                        {...register("bIsActive",
                                                            {

                                                            })
                                                        }
                                                        type="switch"
                                                        onChange={e => { onCheckedChange(e) }}

                                                    />
                                                </Col>
                                                <Col md={10}>
                                                    {estatus &&
                                                        <Col>
                                                            <img src="/img/activo.png"></img> <Badge bg="success">Activo</Badge>
                                                        </Col>
                                                    }

                                                    {!estatus &&
                                                        <Col>
                                                            <img src="/img/noactivo.png"></img> <Badge bg="danger">Desactivado</Badge>
                                                        </Col>
                                                    }

                                                </Col>
                                            </Row>


                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>RUC</Form.Label>
                                            <Form.Control
                                                disabled
                                                isInvalid={!!errors.vRuc}
                                                {...register("vRUC",
                                                    {
                                                        required: 'El RUC es requerido'
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vRUC && errors.vRUC.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nombre del contribuyente</Form.Label>
                                            <Form.Control
                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                isInvalid={!!errors.vNombre}
                                                {...register("vNombre",
                                                    {
                                                        required: 'El Nombre es requerido',
                                                        maxLength: 200,
                                                        minLength: 1
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vNombre && errors.vNombre.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Usuario <IconButton
                                                className="ms-2"
                                                iconClassName="fs--2 me-1"
                                                variant="falcon-primary"
                                                size="sm"
                                                icon="circle"
                                                onClick={() => { ObtenerCadenaRandom('Usuario') }}
                                            >
                                                Generar
                                            </IconButton></Form.Label>
                                            <Form.Control
                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                isInvalid={!!errors.vUsuario}
                                                {...register("vUsuario",
                                                    {
                                                        required: 'El usuario es requerido',
                                                        maxLength: 40,
                                                        minLength: 1
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vUsuario && errors.vUsuario.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Contrase&ntilde;a <IconButton
                                                className="ms-2"
                                                iconClassName="fs--2 me-1"
                                                variant="falcon-primary"
                                                size="sm"
                                                icon="circle"
                                                onClick={() => { ObtenerCadenaRandom('vPassword') }}
                                            >
                                                Generar
                                            </IconButton></Form.Label>
                                            <Form.Control
                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                isInvalid={!!errors.vPassword}
                                                {...register("vPassword",
                                                    {
                                                        required: 'La contrase\u00F1a es requerido',
                                                        maxLength: 40,
                                                        minLength: 1
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vPassword && errors.vPassword.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                        </Modal.Body>
                        <Modal.Footer>

                            <Button onClick={() => { setShowEditarRuc(false) }} variant='primary' >Cerrar</Button>
                            {!cargandoDatosParaEditar && <Button type="submit" variant='success' >Registrar</Button>}
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
            {showModalAsignarDTE &&
                <Modal
                    show={showModalAsignarDTE}
                    onHide={() => setShowModalAsignarDTE(false)}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form noValidate onSubmit={handleSubmit(onSubmitAsignarDTE)}>
                        <Modal.Header>
                            <Modal.Title>Asingar plan</Modal.Title>
                            <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => setShowModalAsignarDTE(false)} />

                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    Debes elegir un paquete para asignar al RUC seleccionado.
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Control
                                        type="hidden"
                                        isInvalid={!!errors.idRuc}
                                        {...register("idRuc")
                                        }
                                    />
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Paquete DTE's</Form.Label>
                                        <Form.Select
                                            aria-label="Default select"
                                            defaultValue=""
                                            isInvalid={!!errors.idPeruPlanesFacturacion}
                                            {...register("idPeruPlanesFacturacion",
                                                {
                                                    required: true
                                                })
                                            }
                                        >
                                            <option value="">Selecciona un paquete</option>
                                            {planesDocumentos.map((plan, index) => {
                                                return (<option key={index} value={plan.idPeruPlanesFacturacion}>{plan.vPlanTitle} - {(plan.dCantidad == -1) ? "Ilimitados" : plan.dCantidad} DTE's - ${plan.dPlanPrice}USD</option>)
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.idPeruPlanesFacturacion && errors.idPeruPlanesFacturacion.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => { setShowModalAsignarDTE(false) }} variant='primary' >Cerrar</Button>
                            <Button type="submit" variant='success' >ASIGNAR PLAN</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </>
    );
};

export default PlanesFacturacionPeru;
