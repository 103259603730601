import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'akasia-components/navbar/top/NavbarTop';
import NavbarVertical from 'akasia-components/navbar/vertical/NavbarVertical';
import Footer from 'akasia-components/footer/Footer';
import ProductProvider from 'akasia-components/orden/ProductProvider';

import { onMessage, getToken, deleteToken } from "firebase/messaging";

import { messaging } from "../firebase/firebaseConfig";

import useToken from 'hooks/useToken';

import { toast, Flip } from 'react-toastify';
import ModalSeguimientoLead from '../akasia-components/leads/ModalSeguimientoLead';
import { useState } from 'react';
import MensajeInformativo from '../components/common/MensajeInformativo';
import MensajeErrror from '../components/common/MensajeError';



const MainLayout = () => {
    // const isChat = pathname.includes('chat');
    const { hash, pathname } = useLocation();
    const isKanban = pathname.includes('kanban');
    const { token, setToken } = useToken();
    const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);



    const [isAllRead, setIsAllRead] = useState(0);
    const [showModalSeguimientoLead, setShowModalSeguimientoLead] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [idLead, setIdLead] = useState(0);
    const [notificaciones, setNotificaciones] = useState([]);
    //const [notificacionesSeparadas, setNotificacionesSeparadas] = useState({});
    //const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastNotf, setLastNotf] = useState(0);
    
    /**
     * Guarda el token en la BD
     * @param {any} token
     */
    const verificarSesion = async (tokenFirebase) => {

        var objDataSend = {};

        objDataSend.tokenFirebase = tokenFirebase;

        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/account/VerificarSesion/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }, body: jDataSend
            }).catch((error) => {;
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== VERIFICA SESION ====");
            console.log("response verifica sesion: ", response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 3) {

                var a = await deleteToken(messaging);
                console.log(a);

                setToken(null);
                location.href = "";

            }

        }
        else {
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
     * recupera el token de firebase
     */
    const generarTokenFirebase = async () => {

        //const permission = await Notification.requestPermission();
        //const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js')
        //if (permission === "granted") {
        const tokenFirebase = await getToken(messaging, {
            //serviceWorkerRegistration: registration,
            vapidKey: "BDgxI0yryiAvMlu9vmMDZibB-bmaSt5pj1Vrun_IsGWmzS3h5ubI69nRxId9HteusWK3d1cA1_Y1tFMdV4UjUkE",
        });

            console.log("Token generado : ", tokenFirebase);

            verificarSesion(tokenFirebase);
        //}

    }

    /**
     * Obtiene las 10 notificaciones mas recientes del usuario
     */
    const obtenerNotificaciones = async (_iFirstNotification, _LastNotf) => {


        console.log("minimoNParametro:", _LastNotf);

        //Si _iFirstNotification = 1 El lastNotf No importa porque no se usa en el query.- Solo se trae 15 notificaciones.- Se usa al ejecutar por primera vez
        //Si _iFirstNotification = 0 se trae todas las notificaciones hasta el lastNotf mas 15 nuevas .- Esta funciona para el scroll
        //Si _iFirstNotification = 2 se trae todas las notificaciones hasta lastNotf.- Se usa cuando se actualiza la pagina
        var jDataSend = {};
        jDataSend.idCatNotificacion = -1;
        jDataSend.lastNotification = _LastNotf;
        jDataSend.iFirstNotification = _iFirstNotification;

        console.log("jDataSend: ", jDataSend);

        const response = await fetch("/api/account/obtenerNotificacionesPorCategoria/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend) 
            }).catch((error) => {

                //setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                //setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("============ OBTENER NOTIFICACIONES MAIN LAYOUT ============");
            console.log("response notificaciones: ", response);
            const data = await response.json();
            console.log("data notificaciones: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles notificaciones N-> ", detalles);
                console.log("_iFirstNotification -> ", _iFirstNotification);

                var nNotfcArray = [];
                if (_iFirstNotification == 0) {
                    nNotfcArray = notificaciones;
                }

                detalles.table2.map((ntf) => {
                    nNotfcArray.push(ntf);
                })
                setNotificaciones(nNotfcArray);
                //setNotificaciones([...notificaciones, ...detalles.table2]);
                console.log("minimoL", detalles.table3[0].minimo);
                setLastNotf(detalles.table3[0].minimo);
                //separarNotificacionesPorTitulo(detalles);

            } else {
                console.log("Error: ", data.vMessage);
            }

        }
        else {
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
     * Verifica si el usuario tiene aunque sea una notificacion sin leer 
     */
    const hayNotificacionesNoLeidas = async () => {

        const response = await fetch("/api/account/HayNotificacionesNoLeidas/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }

            }).catch((error) => {

                //setMensajeError(error);
                //setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACIONES NO LEIDAS ====");

            console.log("response notificaciones no leidas: ", response);

            const data = await response.json();
            console.log("data notificaciones no leidas: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setIsAllRead(detalles[0].TotalRegistros);

            } else {

                console.log("Error: ", data.vMessage);

            }

        }
        else {

            //setMensajeError(response.status + ' ' + response.statusText);
            //setShowMensajeError(true);

            console.log(response);
        }
    } 

    /**
     * Separa las notificaciones por titulo
     * @param {any} notificaciones
     */
    //const separarNotificacionesPorTitulo = (notificaciones) => {
    //    const notificacionesSeparadas = {};

    //    notificaciones.forEach(notificacion => {
    //        const { vTitulo, ...restoNotificacion } = notificacion;

    //        if (!notificacionesSeparadas[vTitulo])
    //            notificacionesSeparadas[vTitulo] = [restoNotificacion];
    //        else
    //            notificacionesSeparadas[vTitulo].push(restoNotificacion);

    //    });
    //    console.log("================ NOTIFICACIONES SEPARADAS POR TITULO ================");
    //    console.log("notificaciones separadas: ", notificacionesSeparadas);
    //    setNotificacionesSeparadas(notificacionesSeparadas);
    //};

  useEffect(() => {
      generarTokenFirebase();
      
      setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
      }, 0);

      obtenerNotificaciones(1, lastNotf);
      hayNotificacionesNoLeidas();

      onMessage( messaging, (payload) => {

          obtenerNotificaciones(2, lastNotf);
          hayNotificacionesNoLeidas();

          toast.info(<span dangerouslySetInnerHTML={{ __html: payload.data.vNotificacion }} />, {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Flip,
          });
          
          console.log("payload: ", payload);
          console.log("payload.data: ", payload.data);
          console.log("notificaciones", notificaciones);
      });
      
      

  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);

      generarTokenFirebase();

      obtenerNotificaciones(2, lastNotf);
      hayNotificacionesNoLeidas();

      onMessage(messaging, (payload) => {

          obtenerNotificaciones(2, lastNotf);
          hayNotificacionesNoLeidas();

          toast.info(<span dangerouslySetInnerHTML={{ __html: payload.data.vNotificacion }} />, {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Flip,
          });

          console.log("payload: ", payload);
          console.log("payload.data: ", payload.data);
          console.log("notificaciones", notificaciones);
      });

     

  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <ProductProvider>
        
        <div className={classNames('content', { 'pb-0': isKanban })}>
                  <NavbarTop
                      notificaciones={notificaciones}
                      setIdLead={setIdLead}
                      setShowModalSeguimientoLead={setShowModalSeguimientoLead}
                      isAllRead={isAllRead}
                      setIsAllRead={setIsAllRead}
                      hayNotificacionesNoLeidas={hayNotificacionesNoLeidas}
                      obtenerNotificaciones={obtenerNotificaciones}
                      lastNotf={lastNotf}
                  />
            {/*------ Main Routes ------*/}
            <Outlet />
            {!isKanban && <Footer />}
            {
                <ModalSeguimientoLead
                    showModal={showModalSeguimientoLead}
                    setShowModal={setShowModalSeguimientoLead}
                    setMensajeError={setMensajeError}
                    setShowMensajeError={setShowMensajeError}
                    setMensajeInformativo={setMensajeInformativo}
                    setShowMensajeInformativo={setShowMensajeInformativo}
                    idLead={idLead}
                    setIdLead={setIdLead}
                />
            }
        </div>
        
      </ProductProvider>

          <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
          <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
          {/*<ToastContainer />*/}

    </div>
  );
};

export default MainLayout;
