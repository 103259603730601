import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FeedCardHeader = ({ vNombreUsuario, vFecha, vHora, vComentario, idTipoComentario, vIP, idUsuario }) => {
    return (
        <>
            {
                idTipoComentario == 1 ?
                    <>
                        {
                            idTipoComentario == 1 && <hr className="text-200 m-0 mb-0" />
                        }

                        <Row className="justify-content-between p-2 mb-0">
                            <Col>
                                <Flex>
                                    <Link className="d-flex" to="/perfil">
                                        <Avatar src={"/img/team/team_" + idUsuario + ".jpg"} size="xl" />

                                    </Link>
                                    <div className="flex-1 align-self-center ms-2 mb-0">

                                        <p className="mb-1 lh-1">
                                            <Link className="fw-semi-bold fs--2" to="/perfil">
                                                {vNombreUsuario}
                                            </Link>  •
                                            <span className="mb-0 fs--1  text-500"> {vIP} • {vFecha} • <img src="/img/reloj48.png" width={15}></img> {vHora} • {' '}</span>

                                        </p>
                                        <p className="mb-0  fs--1">
                                            &nbsp;{vComentario}
                                        </p>
                                    </div>

                                </Flex>
                            </Col>

                            <Col xs="auto">
                                {/* <FeedDropdown /> */}
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row className="ps-2 pb-1">
                            <Col className="col-12 align-content-center">
                                <Row>
                                    <Col className="col-12 m-0">
                                        <Link className="fw-semi-bold fs--2" to="/perfil">
                                            {vNombreUsuario}
                                        </Link>  • {" "}
                                        <span className="mb-0 fs--1  text-500">
                                            {vIP} • {vFecha} • <img src="/img/reloj48.png" width={15}></img> {vHora} • {' '}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="col-12 align-self-center">
                                <span className="fs--1">
                                    <span dangerouslySetInnerHTML={{ __html: vComentario }} />
                                </span>
                            </Col>

                        </Row>
                    </>
            }


        </>

    );
};

FeedCardHeader.propTypes = {
    vNombreUsuario: PropTypes.string,
    vFecha: PropTypes.string,
    vHora: PropTypes.string,
    vComentario: PropTypes.string,
    idTipoComentario: PropTypes.number,
    vIP: PropTypes.string,
    idUsuario: PropTypes.number
};

export default FeedCardHeader;
