import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCloseButton from 'components/common/FalconCloseButton';

import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';

import SoftBadge from 'components/common/SoftBadge';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Dropdown, DropdownButton, Form, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip, CloseButton } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import FeedCard from './comentarios/FeedCard';
import FeedProvider from './comentarios/FeedProvider';
import CopyLabel from '../servicio/CopyLabel';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ModalConciliarPago from '../conciliacion/pagos/ModalConciliarPago';
import ModalSeguimientoLead from '../leads/ModalSeguimientoLead';


const DetalleOrden = () => {
    //Parametros
    const { id } = useParams();

    //AUTENTICACION
    const { token, idCatTipoUsuario, setToken, idUsuario, nombreCompleto } = useToken();

    //datos
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showModalConciliar, setShowModalConciliar] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);

    const [orden, setOrden] = useState([]);
    const [ordenDetalles, setOrdenDetalles] = useState([]);

    const [showModalMarcarPagada, setShowModalRegistrarPago] = useState(false);
    const [showModalGenerarLink, setShowModalGenerarLink] = useState(false);

    const [showModalValidarPago, setShowModalValidarPago] = useState(false);
    const [showModalEditarPago, setShowModalEditarPago] = useState(false);
    const [pagos, setPagos] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [archivos, setArchivos] = useState([]);
    const [validacion, setValidacion] = useState({})
    const [dataForm, setDataForm] = useState({});
    const [formaPago, setFormaPago] = useState([]);
    const [cuentasBancarias, setCuentasBancarias] = useState([]);
    const [mediosDePago, setMediosDePago] = useState([]);
    const [bIsACredito, setIdBIsACredito] = useState(false);
    const [procesandoModal, setProcesandoModal] = useState(false);
    const [comentario, setComentario] = useState('');
    const [procesandoComentario, setProcesandoComentario] = useState(false);
    const [ID_Pago, setID_Pago] = useState(0);
    const [loadingEdit, setLoadingEdit] = useState(false);

    const [loadingDeleteFile, setLoadingDeleteFile] = useState(false);

    const [loadingInfoDetallesOrden, setLoadingInfoDetallesOrden] = useState(false);

    const [seguroStateEliminar, setseguroStateEliminar] = useState({ isOpen: false, idLicencia: null, idOrdenDetalle: null });
    const [modalStateAgregarLicencia, setModalStateAgregarLicencia] = useState({ isOpen: false, isLoading: true, idOrdenDetalle: null });
    const [licenciasModal, setLicenciasModal] = useState([]);
    const [selectedLicencia, setSelectedLicencia] = useState(null);
    const [leadsVinculados, setLeadsVinculados] = useState([]);
    const [showModalSeguimientoLead, setShowModalSeguimientoLead] = useState(false);
    const [idLead, setIdLead] = useState(0);
    const [bPagoAntesOrden, setBPagoAntesOrden] = useState(false);

    const [showCancelarOrdenModal, setShowCancelarOrdenModal] = useState(false);
    const [motivoCancelacionOrden, setMotivoCancelacionOrden] = useState('');
    const [linkValidarNumRegTrib, setLinkValidarNumRegTrib] = useState('');
    //const [habilitarBtnCancelarOrden, setHabilitarBtnCancelarOrden] = useState(true);

    const navigate = useNavigate();

    //Obtiene las licencias por id
    const ObtenerDetallesOrden = async () => {
        setLoadingInfoDetallesOrden(true);

        setDataForm({
            vReferencia: "",
            vArchivo: "",
            dFechaPago: "",
            dSaldoAnterior: orden.dSaldoInsoluto,
            dImportePagado: 0,
            dTipoCambio: 1,
            dSaldoInsoluto: 0,
            idCatFormaPago: "",
            vComprobante: "",
            iNumeroParcialidad: 0,
            idPago: 0
        });



        setValidacion({
            dTotalPago: 0,
            vMoneda: '',
            idPago: 0,
            idCatCuentaBancaria: 0,
            dTipoCambio: 1,
            vReferencia: '',
            vMedioPago: '',
            idCatMedioPago: 0
        });

        const response = await fetch("/api/orden/ObtenerDetallesOrden/?id=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {

                setMensajeError('' + error.message);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error.message);

                setLoadingInfoDetallesOrden(false);
            }); 

        if (response.ok) {
            const data = await response.json();
            console.log("========== DETALLES DE ORDEN ==========");
            console.log("data: ", data);

            //const dFecha1 = data["table1"][0].dFechaVenta;
            //const dFecha2 = data["table1"][0].dCreatedDate;

            //console.log("dFechaVenta: ", new Date(dFecha1).getDate());
            //console.log("dCreatedDate: ", new Date(dFecha2).getDate());
            //console.log("dFechaVenta: ", new Date(dFecha1).getTime());
            //console.log("dCreatedDate: ", new Date(dFecha2).getTime());
            //console.log("diferencia en milisegundos: ", new Date(dFecha1).getTime() - new Date(dFecha2).getTime());
            //console.log("diferencia en dias: ", (new Date(dFecha1).getTime() - new Date(dFecha2).getTime()) / (1000 * 60 * 60 * 24)); 

            setOrden(data["table1"][0]);
            setIdBIsACredito(data["table1"][0].vClave.includes('99'));
            setOrdenDetalles(data["table2"]);
            setCuentasBancarias(data["table3"])
            setFormaPago(data["table4"])
            setPagos(data["table5"])
            setComentarios(data["table6"])
            setArchivos(data["table7"])

            setMediosDePago(data["table8"])

            setLeadsVinculados(data["table9"])

            setBPagoAntesOrden(data["table10"][0].bPagoAntesOrden)

            crearLinkValidarNumRegTrib(data.table1[0].vPais);

            setLoadingInfoDetallesOrden(false);
        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
            setLoadingInfoDetallesOrden(false);
        }

    };

    const mensajeValidacion = (mensaje) => {
        toast.error(mensaje, {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            position: "bottom-left"
        });
    }

    const validarFormularioPago = () => {
        if (dataForm.idCatFormaPago == "") {
            mensajeValidacion('Es necesario que elija la forma de pago');
            setProcesandoModal(false);
            return false;
        }
        if (dataForm.dFechaPago == "") {
            mensajeValidacion('Debes seleccionar la fecha de Pago')
            setProcesandoModal(false);
            return false;
        }
        if (dataForm.vReferencia == "") {
            mensajeValidacion('Es necesario que escribas la referencia, folio o rastreo del pago');
            setProcesandoModal(false);
            return false;
        }
        if (dataForm.dSaldoAnterior <= 0) {
            mensajeValidacion('El saldo a pagar no puede ser menor o igual a: 0');
            setProcesandoModal(false);
            return false;
        }

        if (dataForm.dImportePagado <= 0) {
            mensajeValidacion('El monto del pago debe ser mayor que cero');
            setProcesandoModal(false);
            return false;
        }

        if (dataForm.dSaldoInsoluto < 0) {
            mensajeValidacion('El saldo insoluto no puede ser menor que cero');
            setProcesandoModal(false);
            return false;
        }

        if (dataForm.vArchivo.length == 0) {
            mensajeValidacion('Es obligatorio subir un comprobante de pago');
            setProcesandoModal(false);
            return false;
        }
        return true;
    }

    //Enviar Datos Para Marcar Pagada
    const onSubmitPagada = async () => {
        setProcesandoModal(true);

        var validado = validarFormularioPago();
        if (validado == false)
            return;

        var data = new FormData()
        data.append('FileName', dataForm.vNombreDocumento);
        data.append('FormFile', dataForm.vArchivo);
        data.append('vReferencia', dataForm.vReferencia);
        data.append('idOrden', id);
        data.append('dFechaPago', dataForm.dFechaPago);
        data.append('dSaldoAnterior', orden.dSaldoInsoluto);
        data.append('dImportePagado', dataForm.dImportePagado);
        data.append('dSaldoInsoluto', dataForm.dSaldoInsoluto);
        data.append('idCatFormaPago', dataForm.idCatFormaPago);
        data.append('vMoneda', orden.vMoneda);
        data.append('dTipoCambio', orden.dTipoCambio);

        //Este dato se envia porque lo necesita como obligatorio la API
        data.append('vComprobante', 'XXX');
        data.append('iNumeroParcialidad', 0);
        data.append('idPago', 0);

        const response = await fetch("/api/orden/RegistrarPago", {
            method: 'POST',
            body: data,
            headers:
            {
                'Authorization': 'Bearer ' + token
            }
        }).catch((error) => {

            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);

            //enviar notificacion a los administradores
            var objToActionString = crearObjAction(2, "/ordenes/detalle/" + data.table4[0].idOrden, '', '');
            await guardarNotificacion("Pago Registrado", "<strong>" + nombreCompleto + "</strong> registro un pago en la orden <strong>#" + data.table4[0].idOrden + "</strong>", 0, objToActionString, 5);
           
            ObtenerDetallesOrden();
            setShowModalRegistrarPago(false);

            
        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoModal(false);
    };

    //Enviar Datos Para Registrar Link
    const onSubmitGenerarLink = async () => {
        setProcesandoModal(true);

        var data = new FormData()
        data.append('idOrden', id);
        data.append('dSaldoAnterior', orden.dSaldoInsoluto);
        data.append('dImportePagado', dataForm.dImportePagado);
        data.append('dSaldoInsoluto', dataForm.dSaldoInsoluto);
        data.append('vMoneda', orden.vMoneda);



        const response = await fetch("/api/orden/RegistrarLinkPago", {
            method: 'POST',
            body: data,
            headers:
            {
                'Authorization': 'Bearer ' + token
            }
        }).catch((error) => {

            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            ObtenerDetallesOrden();
            setShowModalGenerarLink(false)
        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoModal(false);
    };

    //Enviar Datos Para Editar Pago No Validado
    const onSubmitEditar = async () => {
        setProcesandoModal(true);

        var validado = validarFormularioPago();
        if (validado == false)
            return;

        var data = new FormData()
        data.append('FileName', dataForm.vNombreDocumento);
        data.append('FormFile', dataForm.vArchivo);
        data.append('vReferencia', dataForm.vReferencia);
        data.append('idOrden', id);
        data.append('vComprobante', dataForm.vComprobante);
        data.append('dFechaPago', dataForm.dFechaPago);
        data.append('dSaldoAnterior', dataForm.dSaldoAnterior);
        data.append('dImportePagado', dataForm.dImportePagado);
        data.append('dSaldoInsoluto', dataForm.dSaldoInsoluto);
        data.append('idCatFormaPago', dataForm.idCatFormaPago);
        data.append('iNumeroParcialidad', dataForm.iNumeroParcialidad);
        data.append('idPago', dataForm.idPago);

        //estos datos se env�an porque lo necesita olbigatoriamente la API
        data.append('vMoneda', orden.vMoneda);
        data.append('dTipoCambio', dataForm.dTipoCambio);


        const response = await fetch("/api/orden/EditarPago", {
            method: 'POST',
            body: data,
            headers:
            {
                'Authorization': 'Bearer ' + token
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            ObtenerDetallesOrden();
            setShowModalEditarPago(false);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoModal(false);
    };

    //Enviar Datos Para Validar
    const onSubmitValidar = async () => {
        setProcesandoModal(true);

        if (validacion.idCatCuentaBancaria == 0) {
            mensajeValidacion('Es necesario que elija una cuenta de banco para validar');
            setProcesandoModal(false);
            return;
        }

        if (validacion.idCatMedioPago == 0) {
            mensajeValidacion('Es necesario que elija el medio de Pago');
            setProcesandoModal(false);
            return;
        }

        if (validacion.dTipoCambio == "") {
            mensajeValidacion('El tipo de cambio debe ser introducido');
            setProcesandoModal(false);
            return;
        }

        if (validacion.dTipoCambio <= 0) {
            mensajeValidacion('El tipo de cambio no puede ser menor o igual a cero');
            setProcesandoModal(false);
            return;
        }

        const response = await fetch("/api/orden/ValidarPago/?idPago=" + validacion.idPago +
            "&idOrden=" + id +
            "&idCatCuentaBancaria=" + validacion.idCatCuentaBancaria +
            "&idCatMedioPago=" + validacion.idCatMedioPago +
            "&vReferencia=" + validacion.vReferencia +
            "&dTipoCambio=" + validacion.dTipoCambio +
            "&vTotalPagoMoneda=" + (validacion.dTotalPago + " " + validacion.vMoneda), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            ObtenerDetallesOrden();
            setShowModalValidarPago(false);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoModal(false);
    };

    const calcularInsoluto = (dSaldoAnterior, dPago) => {
        var dInsoluto = dSaldoAnterior - dPago;
        var idCatFormaPago = dataForm.idCatFormaPago;
        if (!orden.vClave.includes('99')) {
            idCatFormaPago = orden.idCatFormaPago;
        }
        setDataForm({ ...dataForm, dSaldoInsoluto: dInsoluto, dImportePagado: dPago, idCatFormaPago: idCatFormaPago })
    }

    const submitComment = async (e) => {
            e.preventDefault();

        if (comentario == '') {
            return;
        }

        setProcesandoComentario(true);
        const response = await fetch("/api/orden/RegistrarComentario?idOrden=" + id +
            "&vComentario=" + encodeURIComponent(comentario) +
            "&idVendedor=" + orden.idVendedor +
            "&vEmailTrabajo=" + orden.vEmailTrabajo +
            "&vNombreCliente=" + orden.vNombreCliente +
            "&idTipoComentario= 1" , {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            //Enviar notificacion a los administradores de comentarios en una orden
            var jsonData = {};
            jsonData.idOrden = data.table2[0].idOrden;
            var jsonString = JSON.stringify(jsonData)
            console.log("jsonString: ", jsonString);

            var objToActionString = crearObjAction(2, "/ordenes/detalle/" + data.table2[0].idOrden, '', '');
            guardarNotificacion("Comentario en una orden", "<strong>" + nombreCompleto + "</strong> hizo un comentario en la orden  <strong>#" + data.table2[0].idOrden + "</strong> : <i>'" + comentario.substring(0, 8) + "...' </i>", 0, objToActionString, 6, jsonString);

            ObtenerDetallesOrden();
            

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoComentario(false);

        setComentario('');
    };

    /**
     * Guarda un comentario cuando se cancela una orden, puede funcionar para guardar otro tipo de comentarios con badge u otros detalles ademas del texto plano
     * @param {any} vComentario
     */
    const GuardarComentario = async (vComentario,idTipoComentario) => {

        const response = await fetch("/api/orden/RegistrarComentario?idOrden=" + id +
            "&vComentario=" + encodeURIComponent(vComentario) +
            "&idVendedor=" + orden.idVendedor +
            "&vEmailTrabajo=" + orden.vEmailTrabajo +
            "&vNombreCliente=" + orden.vNombreCliente +
            "&idTipoComentario=" + idTipoComentario, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            console.log(" ==== COMENTARIO CANCELACION DE ORDEN ==== ");
            const data = await response.json();

            console.log("data: ",data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);
                console.log("ObjDetalles: ",ObjDetalles);

            } else {

                setShowMensajeError(true);
                setMensajeError(data.vMessage);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoComentario(false);
        setProcesandoModal(false);
        setComentario('');
    }

    //Enviar Datos Para Marcar Pagada
    const onSubmitCargarArchivo = async (e) => {
        e.preventDefault();
        setProcesandoModal(true);

        if (dataForm.vArchivo.length == 0) {
            mensajeValidacion('Es obligatorio cargar un archivo');
            setProcesandoModal(false);
            return false;
        }

        var data = new FormData()
        data.append('FileName', dataForm.vNombreDocumento);
        data.append('FormFile', dataForm.vArchivo);
        data.append('idOrden', id);

        const response = await fetch("/api/orden/CargarArchivo", {
            method: 'POST',
            body: data,
            headers:
            {
                'Authorization': 'Bearer ' + token
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            ObtenerDetallesOrden();
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoModal(false);
    };


    const ConfigurarNoPorDefinir = () => {
        if (!orden.vClave.includes('99')) {
            calcularInsoluto(orden.dSaldoInsoluto, orden.dSaldoInsoluto);
        }
    }

    /**
     * Verifica si orden tiene pagos
     * @param {any} action
     * 1.- Editar
     * 2.- Cancelar orden
     */
    const verificaSiOrdenTienePagos = async (action) => {

        const response = await fetch("/api/orden/revisarPagosOrdenes/?idOrden=" + orden.idOrden, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        .catch((error) => {

            setMensajeError('' + error.message);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error.message);
        });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                //Edita
                if (action == 1)
                    navigate("/ordenes/nueva/" + orden.idClienteAkasia + "/" + orden.idOrden);

                //Cancela
                if (action == 2) {
                    setShowCancelarOrdenModal(true);
                    //cancelarOrden();
                }
                    

            } else {

                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);

        }

        setLoadingEdit(false);
    }

    /**
     * Cancela orden
     * @param {any} idOrden
     */
    const cancelarOrden = async () => {

        setLoadingEdit(true);

        const response = await fetch("/api/orden/cancelarOrden/?idOrden=" + orden.idOrden, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        .catch((error) => {

            setMensajeError('' + error.message);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error.message);
        });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                //Guardamos un comentario,cerramos el modal y vaciamos el state con el motivo de cnacelacion de la orden
                GuardarComentario("<b class='badge badge-soft-danger'> Orden cancelada </b> Motivo: <b>" + motivoCancelacionOrden + "</b>",2);
                setShowCancelarOrdenModal(false);
                setMotivoCancelacionOrden("");

                //Enviamos una notificaci�n a los Administradores que se cancel� una orden
                var objToActionString = crearObjAction(2, "/ordenes/detalle/" + orden.idOrden, '', '');
                var jsonData = {};
                jsonData.idOrden = orden.idOrden;
                var jsonString = JSON.stringify(jsonData)
                console.log("jsonString: ", jsonString);
                await guardarNotificacion("Orden cancelada",
                    "<strong>" + nombreCompleto + "</strong> canceló la orden <strong>#" + orden.idOrden + "</strong>",
                    0,
                    objToActionString,
                    8,
                    jsonString
                );

                setMensajeInformativo(responseType.vMessage);
                setShowMensajeInformativo(true);

                //Mejora posible: No recargar pagina, solo hacer los setState necesarios para evitar errores de renderizado
                ObtenerDetallesOrden();

            } else {

                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);

        }

        setLoadingEdit(false);
    }

    /**
   * Elimina archivos
   */
    const eliminaArchivo = async (idOrdenArchivo_, id_, vNombreArchivo_) => {

        setLoadingDeleteFile(true);

        const response = await fetch("/api/orden/deleteFile/?idOrdenArchivo=" + idOrdenArchivo_ + "&idOrden=" + id_ + "&name=" + vNombreArchivo_,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error);

                setLoadingDeleteFile(false);
            });

        if (response.ok) {
            const objResponse = await response.json();
            console.log(objResponse);

            if (objResponse.iResponseType == 1) {
                const data = JSON.parse(objResponse.detalles);
                setArchivos(data);

                setMensajeInformativo("Archivo Eliminado!");
                setShowMensajeInformativo(true);



            } else {
                setMensajeError(objResponse.vMessage);
                setShowMensajeError(true);
            }

            setLoadingDeleteFile(false);
        }
        else {
            setMensajeError('Hubo un problema con la peticion Fetch:');
            setShowMensajeError(true);

            setLoadingDeleteFile(false);
        }


    }

    /**
 * Elimina Pago
 */
    const eliminaPago = async (idPago) => {

        const response = await fetch("/api/conciliacion/activaPago/?idPago=" + idPago + "&bIsActive=0",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            const objResponse = await response.json();
            console.log(objResponse);

            if (objResponse.iResponseType == 1) {

                setMensajeInformativo("Pago Eliminado Correctamente!");
                setShowMensajeInformativo(true);
                ObtenerDetallesOrden();

            } else {
                setMensajeError(objResponse.vMessage);
                setShowMensajeError(true);
            }
        }
        else {
            setMensajeError('Hubo un problema con la peticion Fetch:');
            setShowMensajeError(true);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }


    }


    const obtenerLicenciasParaModal = async (vCodigoBarras, idOrdenDetalle, idClienteAkasia) => {

        console.log(vCodigoBarras);

        setModalStateAgregarLicencia({ isOpen: true, isLoading: true, idOrdenDetalle: idOrdenDetalle });

        setLicenciasModal([]);
        const response = await fetch("/api/orden/act_obtenerLicenciasParaModal/?vCodigoBarras=" + vCodigoBarras + "&idClienteAkasia=" + idClienteAkasia + "", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
                return;
            });

        if (response.ok) {

            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {
                var jsonObjLicencias = JSON.parse(data.detalles);

                console.log(jsonObjLicencias);

                setLicenciasModal(jsonObjLicencias);



            }

            setModalStateAgregarLicencia({ isOpen: true, isLoading: false, idOrdenDetalle: idOrdenDetalle });


            //setLicenciasModal(data['table1']);

        }
        else {
            setModalStateAgregarLicencia({ isOpen: true, isLoading: false, idOrdenDetalle: idOrdenDetalle });

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

        //setModalStateAgregarLicencia({ isOpen: true, idOrdenDetalle: idOrdenDetalle });
    }

    const cancelarLicenciaOrden = async (idLicencia, idOrdenDetalle) => {

        var objNuevaOrden = {};

        objNuevaOrden.idLicencia = idLicencia;
        objNuevaOrden.idOrdenDetalle = idOrdenDetalle;

        var jsonLicenciaOrdenDetalle = JSON.stringify(objNuevaOrden);

        //console.log(jsonOrden);
        //console.log(objNuevaOrden);

        //setConfirmandoOrden(true);

        const response = await fetch("/api/orden/act_EliminarLicenciaOrdenDetalle/", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: jsonLicenciaOrdenDetalle + "",
        }).catch((error) => {

            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {
                ObtenerDetallesOrden();
                setseguroStateEliminar({ isOpen: false, idLicencia: null, idOrdenDetalle: null });
                toast.success(data.vMessage, {
                    theme: 'colored',
                    position: "bottom-right",
                    limit: 1
                });
            } else {
                //setConfirmandoOrden(false);
                toast.error(data.vMessage, {
                    theme: 'colored',
                    position: "bottom-right",
                    limit: 1
                });
            }




        }
        else {

            //setConfirmandoOrden(false);

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    }

    const guardarLicenciaOrdenDetalle = async (idLicencia, idOrdenDetalle) => {
        var objNuevaOrden = {};

        objNuevaOrden.idLicencia = idLicencia;
        objNuevaOrden.idOrdenDetalle = idOrdenDetalle;

        var jsonLicenciaOrdenDetalle = JSON.stringify(objNuevaOrden);

        const response = await fetch("/api/orden/act_GuardarNuevaOrdenDetalle/", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: jsonLicenciaOrdenDetalle + "",
        }).catch((error) => {

            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {
                ObtenerDetallesOrden();
                setModalStateAgregarLicencia({ isOpen: false, idOrdenDetalle: null });
                setMensajeInformativo(data.vMessage);
                setShowMensajeInformativo(true);
            } else {
                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }




        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    }

    /**
     * Crea un objeto de acci�n que especifica la acci�n a realizar al hacer clic en una notificaci�n,
     * ya sea abrir un modal o redireccionar a otra p�gina.
     * @param {any} TypeAction
     * @param {any} vURL
     * @param {any} idModalType
     * @param {any} idLead
     * @returns
     */
    const crearObjAction = (TypeAction, vURL, idModalType, idLead,) => {
        var objToAction = {};
        var objDetalles = {};
        var objData = {};

        objData.idLead = idLead;

        objDetalles.vUrl = vURL;
        objDetalles.idModalType = idModalType;
        objDetalles.data = objData

        objToAction.TypeAction = TypeAction;
        objToAction.detalles = objDetalles;

        var objToActionString = JSON.stringify(objToAction);
        console.log("objToAction de la Orden: ", objToActionString);
        return objToActionString;
    };

    /**
     * Guarda una notificaci�n
     */
    const guardarNotificacion = async (vTitulo, vCuerpo, idUsuarioTarget, objToAction, idTipoNotificacion, jsonData = null) => {

        //console.log("vCuerpo: ", vCuerpo);

        var jDataSend = {};
        jDataSend.vTitulo = vTitulo;
        jDataSend.vCuerpo = vCuerpo;
        jDataSend.idUsuarioTarget = idUsuarioTarget;
        jDataSend.objToAction = objToAction;
        jDataSend.idTipoNotificacion = idTipoNotificacion;
        jDataSend.jsonData = jsonData;

        console.log("jDataSend: ", jDataSend);

        const response = await fetch("/api/leads/guardarNotificacion/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACI�N GUARDADA ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);
            console.log("objToAction: ", objToAction);
            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                var notificacionesList = detalles.table2;
                console.log("notificacionesList: ", notificacionesList);

                notificacionesList.map((objNotify) => {
                    sendPushNotificationRealTime(objNotify.vToken, objNotify.vCuerpo);
                })


            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
        }
    };

    /**
     * Envia una notificacion push al usuario con el token que recibe como parametro
     * @param {any} token
     * @param {any} title
     * @param {any} body
     */
    const sendPushNotificationRealTime = async (vToken, vNotificacion) => {

        const responseTokenFCM = await fetch("/api/account/generateToken/",
        {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {

            console.log('Hubo un problema con la peticion Fetch del token:' + error);
        });


        //console.log("TOKEN TOKEN:", token);
        console.log("responseTokenFCM:", responseTokenFCM);

        if (responseTokenFCM.ok) {
            const dataFCM = await responseTokenFCM.json();
            console.log("data", dataFCM);

            var tokenFCM = dataFCM.detalles;

            try {
                const response = await fetch('https://fcm.googleapis.com/v1/projects/akasia-admin-panel/messages:send', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + tokenFCM,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        message: {
                            token: vToken,
                            data: { vNotificacion: vNotificacion }
                        }
                    })
                });

                const dataNtf = await response.json();
                console.log('Notificaci�n enviada:', dataNtf);

            } catch (error) {
                console.error('Error al enviar la notificaci�n:', error.message);
            }
        }

        //POST 
        //https://fcm.googleapis.com/fcm/send

    };

    const crearLinkValidarNumRegTrib = (vPais) => {

        console.log("vPais: ", vPais);
        if (vPais == "MÉXICO") {
            setLinkValidarNumRegTrib(" https://agsc.siat.sat.gob.mx/PTSC/ValidaRFC/index.jsf");
        } else if (vPais == "HONDURAS") {
            setLinkValidarNumRegTrib("https://www.sar.gob.hn/consulta-rtn/");
        } else if (vPais == "REPÚBLICA DOMINICANA") {
            setLinkValidarNumRegTrib("https://www.dgii.gov.do/app/WebApps/ConsultasWeb/consultas/rnc.aspx");
        } else if (vPais == "GUATEMALA") {
            setLinkValidarNumRegTrib("https://portal.sat.gob.gt/portal/consulta-cui-nit/");
        } else if (vPais == "COLOMBIA") {
            setLinkValidarNumRegTrib("https://muisca.dian.gov.co/WebRutMuisca/DefConsultaEstadoRUT.faces");
        } else if (vPais == "ECUADOR") {
            setLinkValidarNumRegTrib("https://srienlinea.sri.gob.ec/sri-en-linea/SriRucWeb/ConsultaRuc/Consultas/consultaRuc");
        } else if (vPais == "PERÚ") {
            setLinkValidarNumRegTrib("https://e-consultaruc.sunat.gob.pe/cl-ti-itmrconsruc/FrameCriterioBusquedaWeb.jsp");
        } else if (vPais == "BOLIVIA") {
            setLinkValidarNumRegTrib("https://ov.impuestos.gob.bo/");
        } else if (vPais == "ARGENTINA") {
            setLinkValidarNumRegTrib("https://servicioscf.afip.gob.ar/publico/crmcit/consulta.aspx");
        } else if (vPais == "CHILE") {
            setLinkValidarNumRegTrib("https://zeus.sii.cl/cvc/stc/stc.html");
        } else if (vPais == "COSTA RICA") {
            setLinkValidarNumRegTrib("https://atv.hacienda.go.cr/ATV/frmConsultaSituTributaria.aspx");
        } else {
            setLinkValidarNumRegTrib("");
        }
    }; 

    //ejecutar solo 1 vez despues del DOM
    useEffect(() => {
        ObtenerDetallesOrden();
    }, [id]);

    return (
        <>
            <Card className="mb-3 p-3">
                <Row className="g-2 align-items-sm-center">
                    <Col>
                        <h5>
                            {loadingInfoDetallesOrden ? <Skeleton width={300} /> : <>
                                DETALLE DE LA ORDEN: # {orden.idOrden}
                            </>
                            }

                        </h5>
                    </Col>
                    <Col md="auto">
                        {loadingInfoDetallesOrden ? <Skeleton width={100} /> : <>
                            {orden.bIsActive &&
                                <DropdownButton className='me-2 mb-1'
                                    variant="falcon-primary"
                                    title="Acciones"
                                    size="sm">
                                    <Dropdown.Item>
                                        {loadingEdit ?
                                            <span className="text-muted fw-bold">
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> Procesando
                                            </span>
                                            :
                                            <span className=" ms-1 text-dark" onClick={() => { setLoadingEdit(true); verificaSiOrdenTienePagos(1); }}>
                                                Editar Orden
                                            </span>
                                        }




                                    </Dropdown.Item>

                                    <Dropdown.Divider as="div" />
                                    <Dropdown.Item onClick={() => { setMensajeError('Por el momento no es posible enviar por e-mail'); setShowMensajeError(true) }}>Enviar confirmaci&oacute;n de venta por e-mail</Dropdown.Item>
                                    <Dropdown.Divider as="div" />
                                    <Dropdown.Item>
                                        {loadingEdit ?
                                            <span className="text-muted fw-bold">
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> Procesando
                                            </span>
                                            :
                                            <span className=" ms-1 text-danger" onClick={() => { setLoadingEdit(true); verificaSiOrdenTienePagos(2); }}>
                                                Cancelar Orden
                                            </span>
                                        }
                                    </Dropdown.Item>
                                </DropdownButton>
                            }
                        </>
                        }
                    </Col>
                    <Col md="auto">
                        {loadingInfoDetallesOrden ? <Skeleton width={100} /> : <>
                            {orden.bIsActive &&
                                <Button size="sm" className='me-2 mb-1'
                                    onClick={() => mensajeValidacion('No tienes permisos para generar una factura fiscal')}
                                    variant="falcon-primary"
                                >Facturar venta</Button>
                            }
                        </>

                        }

                    </Col>
                </Row>
                {loadingInfoDetallesOrden ? <Skeleton width={250} /> : <p className="fs--2 mt-1">{orden.dFechaVentaLarga + (orden.vMoneda == 'USD' ? "   |   T.C." + orden.dTipoCambio : "")}</p>}
                <Row>
                    <Col md={8}>
                        <div>
                            {loadingInfoDetallesOrden ? <Skeleton width={200} /> : <>
                                <strong className="me-2 fs--1">Estatus: </strong>


                                <SoftBadge
                                    pill
                                    bg={orden.bIsRecompra ? 'primary' : 'success'}
                                    className="fs--1"
                                >
                                    <span className="mb-0">
                                        {orden.bIsRecompra && 'Re-compra'}
                                        {!orden.bIsRecompra && 'Primer Compra'}
                                    </span>
                                    <FontAwesomeIcon
                                        icon={classNames({
                                            check: true
                                        })}
                                        transform="shrink-2"
                                        className="ms-1"
                                    />
                                </SoftBadge>



                                &nbsp;
                                {orden.bIsActive == 1 && <>
                                    <SoftBadge
                                        pill
                                        bg={orden.bIsPagada ? 'success' : 'warning'}
                                        className="fs--1"
                                    >
                                        <span className="mb-0">
                                            {orden.bIsPagada === true && 'Pagada'}
                                            {orden.bIsPagada === false && 'No pagada'}
                                        </span>
                                        <FontAwesomeIcon
                                            icon={classNames({
                                                check: orden.bIsPagada === true,
                                                stream: orden.bIsPagada === false
                                            })}
                                            transform="shrink-2"
                                            className="ms-1"
                                        />
                                    </SoftBadge>
                                    &nbsp;
                                    {orden.dSaldoInsoluto > 0 &&
                                        <SoftBadge
                                            pill
                                            bg={'warning'}
                                            className="fs--1"
                                        >
                                            <span className="mb-0">
                                                <span>Saldo Insoluto: &nbsp;&nbsp; ${parseFloat(orden.dSaldoInsoluto).toLocaleString("es-MX", {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2
                                                })} {orden.vMoneda}</span>
                                            </span>
                                            <FontAwesomeIcon
                                                icon={classNames({
                                                    stream: true
                                                })}
                                                transform="shrink-2"
                                                className="ms-1"
                                            />
                                        </SoftBadge>}
                                </>}
                                {orden.bIsActive == 0 &&
                                    <SoftBadge
                                        pill
                                        bg="danger"
                                        className="fs--1"
                                    >
                                        <span className="mb-0">
                                            Cancelada
                                        </span>
                                        <FontAwesomeIcon
                                            icon="ban"
                                            transform="shrink-2"
                                            className="ms-1"
                                        />
                                    </SoftBadge>}
                            </>}
                        </div>
                        <div>
                            {loadingInfoDetallesOrden ? <Skeleton width={200} /> : <>
                                <strong className="me-2 fs--1 text-center">Vendedor:&nbsp;&nbsp;</strong> <strong className="me-2 fs--2 text-center"><img height="20" src="/img/vendedor35.png"></img> {orden.vNombreVendedor}</strong>
                            </>}
                        </div>
                    </Col>
                    <Col >
                        {loadingInfoDetallesOrden ? <Skeleton width={200} /> : <><h5 className="mb-3 fs-0">Forma de pago</h5></>}

                        {loadingInfoDetallesOrden ? <Skeleton width={200} /> : <>
                            <p className="mb-0 fs--1">
                                {orden.vClave == '01' && <img src="/img/efectivo20.png"></img>}
                                {orden.vClave == '03' && <img src="/img/transferencia20.png"></img>}
                                {orden.vClave == '99' && <img src="/img/pordefinir20.png"></img>}
                                {orden.vClave == '28' && <img src="/img/tarjeta20.png"></img>}
                                {orden.vClave == '04' && <img src="/img/tarjeta20.png"></img>}
                                {orden.vClave == '31' && <img src="/img/intermediario20.png"></img>}
                                &nbsp;{orden.vClave}  {orden.vDescripcionFormaPago}
                            </p>
                        </>}
                    </Col>
                </Row>

            </Card>
            {orden.dFechaPrimerPago < orden.dFechaVenta &&
                <Alert variant="danger">
                    <Alert.Heading>Oops! Tienes un error!</Alert.Heading>
                    <p>
                        La orden tiene fecha de {orden.dFechaVentaLarga}, el pago m&aacute;s antiguo registrado tiene fecha de {orden.dFechaPrimerPagoLarga}. Se puede observar que la orden tiene una fecha posterior al pago, esto no debe ocurrir, las fechas de las &oacute;rdenes deben ser siempre anteriores o el mismo d&iacute;a del primer pago. Edita la orden y selecciona la fecha del primer pago.
                    </p>
                </Alert>}
            <Card className="mb-3">
                <Card.Body>
                    <Row>
                        <Col md={6} lg={4} className="mb-4 mb-lg-0">
                            {loadingInfoDetallesOrden ? <Skeleton width={300} count={5} /> : <>
                                <h5 className="mb-3 fs-0">Datos del cliente</h5>
                                <h5 className="mb-2"><Link to={"/clientes/detalle/" + orden.idClienteAkasia}>{orden.vNombreCliente}</Link></h5>
                                <p className="mb-1 fs--1">
                                    {orden.vEstadoDepProv}, {orden.vPais}
                                </p>
                                <p className="mb-0 fs--1">
                                    <strong>
                                        Email: <a href={"mailto:" + orden.vEmail}><span className="text-lowercase">{orden.vEmail}</span></a>
                                    </strong>
                                </p>
                                <p className="mb-0 fs--1">
                                    <strong>
                                        Tel&eacute;fono: <a href={"https://wa.me/" + orden.vTelefonoPersonal} target="_blank" rel="noreferrer"><img width="25" src="/img/whatsapp40.png"></img> {orden.vTelefonoPersonal}</a>
                                    </strong>
                                </p>
                                {orden.vTelefonoTrabajo &&
                                    <p className="mb-0 fs--1">
                                        <strong>
                                            Tel&eacute;fono Trabajo: <a href={"https://wa.me/" + orden.vTelefonoTrabajo} target="_blank" rel="noreferrer"><img width="25" src="/img/whatsapp40.png"></img>  {orden.vTelefonoTrabajo}</a>
                                        </strong>
                                    </p>
                                }
                                <hr></hr>
                                <p className="mb-1 fs--1">Orden creada el {orden.dFechaRegistro}</p>
                                {
                                    orden.vClave == "99" ?
                                        <>
                                            <p className="mb-1 fs--1">Fecha limite de pago&nbsp; 
                                                {
                                                    new Date(orden.dFechaVencimiento).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'long', day: '2-digit'
                                                    })
                                                }
                                            </p>
                                        </>
                                    :
                                        <>

                                        </>
                                } 
                                
                                {//new Date(orden.dFechaVenta).getDate() - new Date(orden.dCreatedDate).getDate() < -3 && //asi estaba anteriormente
                                    //(new Date(orden.dFechaVenta).getTime() - new Date(orden.dCreatedDate).getTime()) / (1000 * 60 * 60 * 24) <= -3 &&//se calcula la diferencia en milisegundos, se transforma a dias, y se compara con -3
                                    bPagoAntesOrden &&
                                    <SoftBadge
                                        pill
                                        bg={'danger'}
                                        className="fs--2"
                                    >
                                        <span className="mb-0">
                                            INCIDENCIA. Orden registrada 3 o m&aacute;s d&iacute;as despu&eacute;s del pago!!!
                                        </span>
                                    </SoftBadge>
                                }
                                
                            </>}

                        </Col>
                        <Col md={6} lg={4} className="mb-4 mb-lg-0">
                            {loadingInfoDetallesOrden ? <Skeleton width={300} count={5} /> : <>
                                <h5 className="mb-3 fs-0">Datos de facturaci&oacute;n</h5>
                                {/*<h6 className="mb-2"><strong>{orden.vRfc}</strong></h6>*/}
                                {
                                    <h6 className="mb-2 p-0">
                                        {
                                            orden.vPais == 'MÉXICO' ?
                                            <>
                                        
                                                <CopyLabel code={orden.vRfc} placement={"left"} padding="0px" />
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip style={{ position: 'fixed' }} >
                                                            Validar
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a href={linkValidarNumRegTrib} target="_blank" rel="noopener noreferrer">
                                                        <img width="25" src="/img/validar96.png"></img>
                                                    </a>
                                                </OverlayTrigger>
                                            </>
                                            :
                                            <>
                                                <strong>{orden.vRfc}</strong>
                                            </>
                                        }
                                        
                                    </h6>
                                }
                                {
                                    orden.vNumRegTrib &&
                                    <h6 className="mb-2">
                                            <CopyLabel code={orden.vNumRegTrib} placement="top" padding="0px" />
                                            {
                                                linkValidarNumRegTrib && /*Si el state esta vacio significa que no tenemos un link para validar el NumRegTRib de ese pais */
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip style={{ position: 'fixed' }} >
                                                            Validar
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a href={linkValidarNumRegTrib} target="_blank" rel="noopener noreferrer">
                                                        <img width="25" src="/img/validar96.png"></img>
                                                    </a>
                                                </OverlayTrigger>
                                            }
                                            
                                        </h6>
                                }
                                <h6 className="mb-2">{orden.vRazonSocial}</h6>
                                <p className="mb-0 fs--1">
                                    {orden.vDireccionFiscal} CP: {orden.vCp}
                                </p>
                                <p className="mb-0 fs--1">
                                    {orden.vCodigoUso}  {orden.vDescripcionUsoCFDI}
                                </p>
                                <p className="mb-0 fs--1">
                                    {orden.vCodigoRegimen}  {orden.vDescripcionRegimenFiscal}
                                </p>

                                {orden.vEmailFacturacion && 
                                    <p className="mb-0 fs--1">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip style={{ position: 'fixed' }} >
                                                    Enviar correo
                                                </Tooltip>
                                            }
                                        >
                                            <a href={"mailto:" + orden.vEmailFacturacion}>
                                                <img width="25" src="/img/correo96.png"></img>
                                            </a>
                                        </OverlayTrigger>
                                        <CopyLabel
                                            code={orden.vEmailFacturacion}
                                            placement={"right"}
                                            padding="5px"
                                        />
                                    </p>    
                                }

                                {orden.vTelefonoFacturacion && 
                                    <p className="mb-0 fs--1">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip style={{ position: 'fixed' }} >
                                                    Enviar whatsapp
                                                </Tooltip>
                                            }
                                        >
                                            <a href={"https://wa.me/" + orden.vTelefonoFacturacion} target="_blank" rel="noreferrer">
                                                <img width="25" src="/img/whatsapp40.png"></img>
                                            </a>
                                        </OverlayTrigger>
                                        <CopyLabel
                                            code={orden.vTelefonoFacturacion}
                                            placement={"right"}
                                            padding="5px"
                                        />
                                    </p>
                                }
                                
                                
                            </>}
                        </Col>
                        <Col md={6} lg={4}>
                            {loadingInfoDetallesOrden ? <Skeleton width={300} count={5} /> : <>
                                <h5 className="mb-3 fs-0">Historial de pagos</h5>
                                {pagos.map((p, i) => {
                                    return (
                                        <div key={i}>
                                            <Row className="fs--1">
                                                <Col>
                                                    {!p.vUUIDLinkPago && p.idCatCuentaBancaria == 0 && <Link to={""} onClick={() => {
                                                        setShowModalEditarPago(true);
                                                        setDataForm({
                                                            vReferencia: p.vReferencia,
                                                            vArchivo: "",
                                                            dFechaPago: p.dFechaPago,
                                                            dSaldoAnterior: p.dSaldoAnterior,
                                                            dImportePagado: p.dImportePago,
                                                            dSaldoInsoluto: p.dSaldoInsoluto,
                                                            idCatFormaPago: p.idCatFormaPago,
                                                            dTipoCambio: p.dTipoCambio,
                                                            iNumeroParcialidad: p.iNumeroParcialidad,
                                                            idPago: p.idPago,
                                                            vComprobante: p.vComprobante
                                                        });
                                                    }}>
                                                        <img src="/img/editar20.png"></img>
                                                    </Link>}<strong>Pago #
                                                        {idCatTipoUsuario == 1 ?
                                                            <Link onClick={() => { setID_Pago(p.idPago); setShowModalConciliar(true) }}>{p.idPago}</Link>
                                                            :
                                                            <span>{p.idPago}</span>
                                                        }</strong>
                                                </Col>
                                                <Col>
                                                    {p.dFechaPagoLarga}
                                                </Col>
                                                <Col md="auto">
                                                    <strong>${parseFloat(p.dImportePago).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2
                                                    })} {p.vMoneda}</strong>
                                                </Col>
                                            </Row>

                                            <p className="mb-0 fs--1">
                                                REF. {p.vReferencia}
                                            </p>

                                            {p.vComprobante && !p.vUUIDLinkPago &&
                                                <p className="mb-0 fs--1">
                                                    {p.vComprobante.includes('.pdf') && <img src="/img/pdf20.png"></img>}
                                                    {!p.vComprobante.includes('.pdf') && <img src="/img/imagen20.png"></img>}

                                                    &nbsp;<Link className="stretched-link text-info position-relative" to={"/api/orden/onget/?name=" + p.vComprobante} target="_blank">{p.vComprobante}</Link>

                                                </p>
                                            }
                                            {p.vUUIDLinkPago && <p className="mb-0 fs--1"><a href={"https://payments.akasia.mx/?id=" + p.vUUIDLinkPago} target="_blank" rel="noreferrer">https://payments.akasia.mx/?id={p.vUUIDLinkPago}</a></p>}
                                            {p.vDescripcionFormaPago && <p className="mb-0 fs--1">{p.vClave} {p.vDescripcionFormaPago}</p>}
                                            {p.idCatCuentaBancaria > 0 && <p className="mb-0 fs--1">
                                                {p.vMedioPago} - {p.vNumCta}- {p.vAlias} &nbsp;&nbsp;&nbsp; {p.dTipoCambio > 1 && <span>T. C.: {p.dTipoCambio}</span>}
                                            </p>

                                            }
                                            <Row>
                                                <Col className="mt-1">
                                                    <SoftBadge
                                                        pill
                                                        bg={p.bIsPagado > 0 ? 'success' : 'danger'}
                                                        className="fs--2"
                                                    >
                                                        <span className="mb-0">
                                                            {p.bIsPagado == 1 && 'Pagado'}
                                                            {p.bIsPagado == 0 && 'No pagado'}
                                                        </span>
                                                        <FontAwesomeIcon
                                                            icon={classNames({
                                                                check: p.idCatCuentaBancaria > 0,
                                                                stream: p.idCatCuentaBancaria == 0
                                                            })}
                                                            transform="shrink-2"
                                                            className="ms-1 auto"
                                                        />
                                                    </SoftBadge>&nbsp;
                                                    {p.bIsPagado == 1 &&
                                                        <>
                                                            <SoftBadge
                                                                pill
                                                                bg={p.idCatCuentaBancaria > 0 ? 'success' : 'warning'}
                                                                className="fs--2"
                                                            >
                                                                <span className="mb-0">
                                                                    {p.idCatCuentaBancaria > 0 && 'Validado'}
                                                                    {p.idCatCuentaBancaria == 0 && 'No validado'}
                                                                </span>
                                                                <FontAwesomeIcon
                                                                    icon={classNames({
                                                                        check: p.idCatCuentaBancaria > 0,
                                                                        stream: p.idCatCuentaBancaria == 0
                                                                    })}
                                                                    transform="shrink-2"
                                                                    className="ms-1 auto"
                                                                />
                                                            </SoftBadge>
                                                            &nbsp;
                                                            <SoftBadge
                                                                pill
                                                                bg={p.bIsFacturado ? 'success' : 'warning'}
                                                                className="fs--2"
                                                            >
                                                                <span className="mb-0">
                                                                    {p.bIsFacturado && 'Facturado'}
                                                                    {!p.bIsFacturado && <a href="#">No facturado</a>}
                                                                </span>
                                                                <FontAwesomeIcon
                                                                    icon={classNames({
                                                                        check: p.bIsFacturado,
                                                                        stream: !p.bIsFacturado
                                                                    })}
                                                                    transform="shrink-2"
                                                                    className="ms-1 auto"
                                                                />
                                                            </SoftBadge>
                                                        </>
                                                    }
                                                </Col>

                                            </Row>
                                            {idCatTipoUsuario == "1" && p.idCatCuentaBancaria == 0 && <>
                                                <hr></hr>
                                                <Row>
                                                    <Col>&nbsp;</Col>
                                                    <Col md="auto" className="mt-1">
                                                        {p.bIsPagado == 1 &&
                                                            <Button size="sm"
                                                                className="fs--2 me-2"
                                                                onClick={() => {
                                                                    setShowModalValidarPago(true);
                                                                    setValidacion({
                                                                        ...validacion,
                                                                        dTotalPago: p.dImportePago,
                                                                        vMoneda: p.vMoneda,
                                                                        idPago: p.idPago,
                                                                        idCatCuentaBancaria: p.idCatCuentaBancaria,
                                                                        dTipoCambio: p.dTipoCambio,
                                                                        vReferencia: p.vReferencia,
                                                                        vMedioPago: ''
                                                                    })
                                                                }}
                                                                variant="falcon-success"
                                                            >Validar pago</Button>
                                                        }
                                                        {p.bIsPagado == 0 &&
                                                            <Button size="sm"
                                                                className="fs--2"
                                                                onClick={() => {

                                                                    eliminaPago(p.idPago)
                                                                }}
                                                                variant="falcon-danger"
                                                            >Eliminar Link</Button>
                                                        }

                                                    </Col>
                                                </Row>
                                            </>}
                                            <hr></hr>
                                        </div>)
                                })}
                                {orden.bIsPagada === false &&
                                    <>
                                        {orden.bIsActive &&
                                            <Button size="sm" className='me-2 mb-2'
                                                onClick={() => { ConfigurarNoPorDefinir(); setShowModalRegistrarPago(true); }}
                                                variant="falcon-primary"
                                            >Registrar pago</Button>
                                        }
                                        {(orden.vClave == '04' || orden.vClave == '28' || orden.vClave == '31' || orden.vClave == '99') && orden.bIsActive &&
                                            <Button size="sm" className='me-2 mb-2'
                                                onClick={() => { ConfigurarNoPorDefinir(); setShowModalGenerarLink(true); }}
                                                variant="falcon-primary"
                                            >Generar Link Pago Tarjeta</Button>
                                        }

                                    </>

                                }
                            </>}
                        </Col>
                        <Col className="col-12 mt-1 fs--1">
                            <Row>
                                {

                                    leadsVinculados.map(
                                        (lead, index) => {
                                            return (
                                               
                                                    <Col key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 pe-1">
                                                        <Row>
                                                            <Col className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 p-0">

                                                                <Link to={"#"} onClick={() => { setIdLead(lead.idLead); setShowModalSeguimientoLead(true); }}>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip style={{ position: 'fixed' }} >
                                                                                Ver informacion de este lead
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon

                                                                            icon="user"
                                                                            transform="shrink-2"
                                                                            className="ms-1"
                                                                        />
                                                                    </OverlayTrigger>
                                                                    &nbsp;
                                                                    {"Lead #" + lead.idLead}
                                                                </Link>

                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                
                                                


                                            )
                                        }
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    {loadingInfoDetallesOrden ? <Skeleton height={300} /> : <>
                        <div className="table-responsive fs--1">
                            <Table striped className="border-bottom">
                                <thead className="bg-200 text-900">
                                    <tr>
                                        <th className="border-0">C&oacute;d. Barras</th>
                                        <th className="border-0">Nombre Producto</th>
                                        <th className="border-0 text-center">Cantidad</th>
                                        <th className="border-0 text-end">Prec. Unit.</th>
                                        <th className="border-0 text-end">Importe</th>
                                        <th className="border-0 text-end">I.V.A.</th>
                                        <th className="border-0 text-end">Total</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    { ordenDetalles.map(product => {

                                        const jsonLicencias = product.vJsonLicencias;

                                        var objLicencias = [];

                                        objLicencias = JSON.parse(jsonLicencias);
                                        let dNumeroLicencias = objLicencias.length;
                                        const dCantidadComprada = product.dCantidad;
                                        let dDiferencia = dCantidadComprada - dNumeroLicencias;

                                        for (let i = 0; i < dDiferencia && dDiferencia > 0; i++) {

                                            let objLicencia = {};
                                            objLicencia.idLicencia = 0;
                                            objLicencia.dInicioLicenciaUso = "";
                                            objLicencia.dFinLicenciaUso = "";
                                            objLicencia.Status = "";
                                            objLicencia.bIsActive = false;

                                            objLicencias.push(objLicencia);

                                        }

                                        return (

                                            <tr key={product.idOrdenDetalle} className="border-200">
                                                <td className="align-middle">
                                                    <p className="mb-0 text-nowrap">{product.vCodigoBarras}</p>
                                                </td>
                                                <td className="align-middle">
                                                    <p className="mb-0 text-nowrap">{product.vNombreProducto}</p>
                                                    <Row>
                                                        {
                                                            objLicencias.map(
                                                                (objLicencia, index) => {
                                                                    return (
                                                                        (objLicencia.idLicencia > 0) ?
                                                                            <Col key={objLicencia.idLicencia} className="col-6 pr-1 mb-2" style={{ cursor: 'pointer' }}>

                                                                                <OverlayTrigger placement="bottom"
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip">
                                                                                            <strong>Remover asignaci&oacute;n</strong>
                                                                                        </Tooltip>}>
                                                                                    <FontAwesomeIcon className="me-2"
                                                                                        onClick={
                                                                                            () => setseguroStateEliminar({ isOpen: true, idLicencia: objLicencia.idLicencia, idOrdenDetalle: product.idOrdenDetalle })}
                                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                                        style={{ color: "#c32847", }}
                                                                                        size="lg" />
                                                                                </OverlayTrigger>

                                                                                <Link to={"/licencias/detalle/" + objLicencia.idLicencia} target="_blank" className="stretched-link text-info position-relative">
                                                                                    <img className="mr-2" src="/img/pcautorizada20.png"></img>
                                                                                    {product.vCodigoBarras} #{objLicencia.idLicencia}

                                                                                    {
                                                                                        (objLicencia.Status == "NO ACTIVADA") ?
                                                                                            <>
                                                                                                <span className="ms-2 badge badge-soft-danger">{objLicencia.Status}</span>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <span className="ms-2 badge badge-soft-success">{objLicencia.Status}</span>
                                                                                            </>
                                                                                    }

                                                                                    {
                                                                                        (objLicencia.bCancelado == 1) &&
                                                                                        <>&nbsp;
                                                                                            <span className="ms-2 badge badge-soft-danger">CANCELADA</span>
                                                                                        </>
                                                                                    }


                                                                                </Link>

                                                                                {/*<span className="text-muted">Click para <strong>asignar licencia</strong></span>*/}

                                                                            </Col>
                                                                            :
                                                                            product.vCodigoBarras == "LIC-SERV" ||
                                                                                product.vCodigoBarras == "LIC-NUB" ||
                                                                                product.vCodigoBarras == "LIC-CAJ" ||
                                                                                product.vCodigoBarras == "LIC-MEN-SERV" ||
                                                                                product.vCodigoBarras == "LIC-APP-COM" ?

                                                                                <Col key={index} className="col-6 pr-1 mb-2" style={{ cursor: 'pointer' }} onClick={() => obtenerLicenciasParaModal(product.vCodigoBarras, product.idOrdenDetalle, orden.idClienteAkasia)}>

                                                                                    {/*{<span className="text-muted">Click para <strong>asignar licencia</strong></span>}*/}
                                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><strong>Asignar licencia</strong></Tooltip>}>
                                                                                        <span>
                                                                                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'file-contract', style: "solid" })} style={{ color: "#28a745", }} size="xl" />
                                                                                            <span className="text-muted">Click para <strong>asignar licencia</strong></span>
                                                                                        </span>
                                                                                    </OverlayTrigger>

                                                                                </Col>
                                                                                :
                                                                                <Col key={index}></Col>


                                                                    );

                                                                }
                                                            )
                                                        }


                                                    </Row>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product.dCantidad}
                                                </td>
                                                <td className="align-middle text-end">

                                                    {parseFloat(product.dPrecioUnitario + (product.dPrecioUnitario * 0.16)).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                        style: 'currency', currency: 'MXN'
                                                    })}

                                                </td>
                                                <td className="align-middle text-end">

                                                    {parseFloat(product.dImporte).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                        style: 'currency', currency: 'MXN'
                                                    })}
                                                </td>
                                                <td className="align-middle text-end">

                                                    {parseFloat(product.dIVA).toLocaleString(
                                                        "es-MX",
                                                        { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'MXN' }
                                                    )}
                                                </td>
                                                <td className="align-middle text-end">

                                                    {parseFloat(product.dTotal).toLocaleString(
                                                        "es-MX",
                                                        { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'MXN' }
                                                    )}
                                                </td>
                                            </tr>
                                        );

                                    }

                                    )}
                                </tbody>
                            </Table>
                            <Row className="g-0 justify-content-end">
                                <Col xs="auto">
                                    <Table borderless size="sm" className="fs--1 text-end">
                                        <tbody>
                                            <tr>
                                                <th className="text-900">Subtotal:</th>
                                                <td className="fw-semi-bold">

                                                    {parseFloat(orden.dSubtotal).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                        style: 'currency', currency: 'MXN'
                                                    })}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-900">IVA 16%:</th>
                                                <td className="fw-semi-bold">
                                                    $
                                                    {parseFloat(orden.dIVA).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2
                                                    })}
                                                </td>
                                            </tr>
                                            <tr className="border-top">
                                                <th className="text-900">Total:</th>
                                                <td className="fw-semi-bold">
                                                    {orden.vMoneda}
                                                    {parseFloat(orden.dTotal).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                        style: 'currency', currency: 'MXN'
                                                    })}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>

                        </div>
                    </>}
                    {archivos.length > 0 &&
                        <>
                            <hr></hr>
                            {loadingInfoDetallesOrden ? <Skeleton height={100} /> : <>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th width={3}></th>
                                            <th width={3}></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {archivos.map((a, i) => {
                                            return (<>

                                                <tr key={i}>
                                                    <td>
                                                        {loadingDeleteFile ? <Skeleton height={20} width={20} /> : <>
                                                            <div onClick={() => eliminaArchivo(a.idOrdenArchivo, a.idOrden, a.vNombreArchivo)} >
                                                                <img src="../../img/noactivo.png"></img>
                                                            </div>
                                                        </>
                                                        }

                                                    </td>
                                                    <td>
                                                        {loadingDeleteFile ? <Skeleton height={20} width={20} /> : <>
                                                            <Link to={"/api/orden/OnGetFileToOpen/?idOrden=" + id + "&name=" + a.vNombreArchivo} target="_blank" className={'d-block link-primary fs--1'} key={i} >
                                                                Ver
                                                            </Link>
                                                        </>
                                                        }

                                                    </td>
                                                    <td>
                                                        {loadingDeleteFile ? <Skeleton height={20} width={350} /> : <>
                                                            <Link to={"/api/orden/ongetfile/?idOrden=" + id + "&name=" + a.vNombreArchivo} target="_blank" className={'d-block link-primary fs--1'} key={i} >
                                                                {a.vNombreArchivo.includes('.pdf') ? <img src="/img/pdf20.png"></img> :
                                                                    a.vNombreArchivo.includes('.PDF') ? <img src="/img/pdf20.png"></img> :
                                                                        a.vNombreArchivo.includes('.xml') ? <img src="/img/xml20.png"></img> :
                                                                            a.vNombreArchivo.includes('.zip') ? <img src="/img/winrar20.png"></img> :
                                                                                a.vNombreArchivo.includes('.rar') ? <img src="/img/winrar20.png"></img> :
                                                                                    a.vNombreArchivo.includes('.jpg') ? <img src="/img/imagen20.png"></img> :
                                                                                        a.vNombreArchivo.includes('.png') ? <img src="/img/imagen20.png"></img> :
                                                                                            a.vNombreArchivo.includes('.jpeg') ? <img src="/img/imagen20.png"></img> :
                                                                                                <img src="/img/archivo20.png"></img>}
                                                                &nbsp;{a.vNombreArchivo}
                                                            </Link>
                                                        </>
                                                        }

                                                    </td>
                                                </tr>

                                            </>)
                                        })}

                                    </tbody>
                                </Table>

                            </>}
                        </>}
                    {idCatTipoUsuario == "1" &&
                        <>
                            <hr></hr>
                            <Row>
                                <Col>
                                    {loadingInfoDetallesOrden ? <Skeleton height={50} /> : <>
                                        <Form onSubmit={onSubmitCargarArchivo}>
                                            <Form.Group controlId="formFileSm" className="mb-3">
                                                <Form.Label>Nota de Venta / Factura pdf o xml / CSF</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    size="sm"
                                                    disabled={procesandoModal}
                                                    onChange={(e) => setDataForm({
                                                        ...dataForm,
                                                        vArchivo: e.target.files[0],
                                                        vNombreDocumento: e.target.files[0].name,
                                                        vTipoDocumento: e.target.files[0].type,
                                                        vTamano: e.target.files[0].size,
                                                    })}
                                                />
                                            </Form.Group>

                                            <Button size="sm"
                                                type="submit"
                                                disabled={procesandoModal}
                                                className="fs--2"
                                                variant="falcon-primary"
                                            >Cargar el documento</Button>

                                        </Form>
                                    </>}
                                </Col>
                            </Row>
                        </>
                    }
                </Card.Body>
            </Card>

            <FeedProvider>
                {loadingInfoDetallesOrden ? <></> : <>
                    <FeedCard
                        comentarios={comentarios}
                        procesandoComentario={procesandoComentario}
                        setComentario={setComentario}
                        comentario={comentario}
                        submitComment={submitComment}
                        idUsuario={Number(idUsuario)}
                        className='mb-3 mb-lg-0'
                    />
                </>}
            </FeedProvider>

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer/>
            {/* Modal para registrar un pago - comprobante de pago */}
            <Modal
                show={showModalMarcarPagada}
                onHide={() => setShowModalRegistrarPago(false)}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Registrar Pago</Modal.Title>
                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalRegistrarPago(false); }} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col
                            md={12}
                            xl={12}
                            xxl={12}
                            className="text-center text-md-center text-xl-center text-xxl-center"
                        >
                            <div className="fs-2 fw-semi-bold">
                                Saldo a Pagar:
                            </div>
                            <div className="fs-4 fw-semi-bold">
                                <span className="text-danger">${parseFloat(orden.dSaldoInsoluto).toLocaleString("es-MX", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}</span> {orden.vMoneda}
                            </div>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Monto del Pago</Form.Label>
                                <Form.Control
                                    onChange={(e) => { calcularInsoluto(orden.dSaldoInsoluto, e.target.value); }}
                                    type="number"
                                    size="md"
                                    required
                                    disabled={!bIsACredito}
                                    value={dataForm.dImportePagado}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Saldo Insoluto</Form.Label>
                                <Form.Control
                                    type="number"
                                    size="md"
                                    required
                                    disabled
                                    value={dataForm.dSaldoInsoluto}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label>Forma de pago</Form.Label>
                        <Form.Select
                            onChange={(e) => setDataForm({ ...dataForm, idCatFormaPago: e.target.value })}
                            className="mb-3"
                            disabled={!bIsACredito}
                            defaultValue={dataForm.idCatFormaPago}
                        >
                            <option value="">Selecciona forma de pago</option>
                            {formaPago.map((fp, index) => {
                                return <option key={index} value={fp.idCatFormaPago}>{fp.vDescripcionFormaPago}</option>
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Fecha del Pago</Form.Label>
                        <Form.Control
                            onChange={(e) => setDataForm({ ...dataForm, dFechaPago: e.target.value })}
                            className="mb-3"
                            type="date" size="md" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Folio / Rastreo</Form.Label>
                        <Form.Control
                            onChange={(e) => setDataForm({ ...dataForm, vReferencia: e.target.value })}
                            type="text"
                            size="md"
                            required
                            value={dataForm.vReferencia}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Comprobante de pago</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            required
                            onChange={(e) => setDataForm({
                                ...dataForm,
                                vArchivo: e.target.files[0],
                                vNombreDocumento: e.target.files[0].name,
                                vTipoDocumento: e.target.files[0].type,
                                vTamano: e.target.files[0].size,
                            })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModalRegistrarPago(false) }} variant='primary' >Cerrar</Button>
                    {procesandoModal ? <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Procesando...
                    </Button> : <Button type="button" onClick={() => onSubmitPagada()} variant='success' >REGISTRAR PAGO</Button>}
                </Modal.Footer>

            </Modal>

            {/* Modal para generar un link de pago Stripe - PAYPAL */}
            <Modal
                show={showModalGenerarLink}
                onHide={() => setShowModalGenerarLink(false)}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Generar Link de Pago</Modal.Title>
                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalRegistrarPago(false); }} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col
                            md={12}
                            xl={12}
                            xxl={12}
                            className="text-center text-md-center text-xl-center text-xxl-center"
                        >
                            <div className="fs-2 fw-semi-bold">
                                Saldo a Pagar:
                            </div>
                            <div className="fs-4 fw-semi-bold">
                                <span className="text-danger">${parseFloat(orden.dSaldoInsoluto).toLocaleString("es-MX", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}</span> {orden.vMoneda}
                            </div>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Monto del Pago</Form.Label>
                                <Form.Control
                                    onChange={(e) => { calcularInsoluto(orden.dSaldoInsoluto, e.target.value); }}
                                    type="number"
                                    size="md"
                                    required
                                    disabled={!bIsACredito}
                                    value={dataForm.dImportePagado}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Saldo Insoluto</Form.Label>
                                <Form.Control
                                    type="number"
                                    size="md"
                                    required
                                    disabled
                                    value={dataForm.dSaldoInsoluto}
                                />
                            </Form.Group>
                        </Col>
                    </Row>



                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModalGenerarLink(false) }} variant='primary' >Cerrar</Button>
                    {procesandoModal ? <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Procesando...
                    </Button> : <Button type="button" onClick={() => onSubmitGenerarLink()} variant='success' >GENERAR LINK</Button>}
                </Modal.Footer>

            </Modal>

            {/* Modal para editar un pago no validado */}
            <Modal
                show={showModalEditarPago}
                onHide={() => setShowModalEditarPago(false)}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Editar Pago</Modal.Title>
                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalEditarPago(false); }} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col
                            md={12}
                            xl={12}
                            xxl={12}
                            className="text-center text-md-center text-xl-center text-xxl-center"
                        >
                            <div className="fs-2 fw-semi-bold">
                                Saldo a Pagar:
                            </div>
                            <div className="fs-4 fw-semi-bold">
                                <span className="text-danger">${parseFloat(dataForm.dSaldoAnterior).toLocaleString("es-MX", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}</span> {orden.vMoneda}
                            </div>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Monto del Pago</Form.Label>
                                <Form.Control
                                    onChange={(e) => { calcularInsoluto(dataForm.dSaldoAnterior, e.target.value); }}
                                    type="number"
                                    size="md"
                                    required
                                    disabled={!bIsACredito}
                                    value={dataForm.dImportePagado}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Saldo Insoluto</Form.Label>
                                <Form.Control
                                    type="number"
                                    size="md"
                                    required
                                    disabled
                                    value={dataForm.dSaldoInsoluto}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label>Forma de pago</Form.Label>
                        <Form.Select
                            onChange={(e) => setDataForm({ ...dataForm, idCatFormaPago: e.target.value })}
                            className="mb-3"
                            defaultValue={dataForm.idCatFormaPago}
                        >
                            <option value="">Selecciona forma de pago</option>
                            {formaPago.map((fp, index) => {
                                return <option key={index} value={fp.idCatFormaPago}>{fp.vDescripcionFormaPago}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Fecha del Pago</Form.Label>
                        <Form.Control
                            onChange={(e) => setDataForm({ ...dataForm, dFechaPago: e.target.value })}
                            className="mb-3"
                            defaultValue={dataForm.dFechaPago}
                            type="date"
                            size="md" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Folio / Rastreo</Form.Label>
                        <Form.Control
                            onChange={(e) => setDataForm({ ...dataForm, vReferencia: e.target.value })}
                            type="text"
                            size="md"
                            required
                            value={dataForm.vReferencia}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Comprobante de pago</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            required
                            onChange={(e) => setDataForm({
                                ...dataForm,
                                vArchivo: e.target.files[0],
                                vNombreDocumento: e.target.files[0].name,
                                vTipoDocumento: e.target.files[0].type,
                                vTamano: e.target.files[0].size,
                            })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModalEditarPago(false) }} variant='primary' >Cerrar</Button>
                    {procesandoModal ? <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Procesando...
                    </Button> : <Button type="button" onClick={() => onSubmitEditar()} variant='success' >GUARDAR CAMBIOS</Button>}
                </Modal.Footer>
            </Modal>

            {/* Modal para validar un pago */}
            <Modal
                show={showModalValidarPago}
                onHide={() => setShowModalValidarPago(false)}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Validar Pago #{validacion.idPago}</Modal.Title>
                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalValidarPago(false); }} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col
                            md={12}
                            xl={12}
                            xxl={12}
                            className="text-center text-md-center text-xl-center text-xxl-center"
                        >
                            <div className="fs-2 fw-semi-bold">
                                Total del Pago:
                            </div>
                            <div className="fs-4 fw-semi-bold">
                                <span className="text-danger">${parseFloat(validacion.dTotalPago).toLocaleString("es-MX", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}</span> {validacion.vMoneda}
                            </div>
                            <hr></hr>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label>Destino del ingreso</Form.Label>
                        <Form.Select
                            onChange={(e) => setValidacion({ ...validacion, idCatCuentaBancaria: e.target.value })}
                            className="mb-3"
                            required
                            defaultValue={validacion.idCatCuentaBancaria}
                        >
                            <option value="">Selecciona destino del ingreso</option>
                            {cuentasBancarias.map((fp, index) => {
                                return <option key={index} value={fp.idCatCuentaBancaria}>CTA - {fp.vNumCta} - {fp.vAlias}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Row>
                        <Col>

                            <Form.Group className="mb-3">
                                <Form.Label>Medio de pago</Form.Label>
                                <Form.Select
                                    onChange={(e) => setValidacion({ ...validacion, idCatMedioPago: e.target.value })}
                                    className="mb-3"
                                    required
                                    defaultValue={validacion.idCatMedioPago}
                                >
                                    <option value="0">Selecciona un medio de pago</option>
                                    {
                                        mediosDePago.map(
                                            (medioPago, index) => {
                                                return <option key={index} value={medioPago.idCatMedioPago}>{medioPago.vMedioPago}</option>
                                            }
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>

                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Tipo de cambio</Form.Label>
                                <Form.Control
                                    onChange={(e) => setValidacion({ ...validacion, dTipoCambio: e.target.value })}
                                    type="number"
                                    size="md"
                                    required
                                    disabled={validacion.vMoneda == 'MXN'}
                                    value={validacion.dTipoCambio}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label>Folio / Rastreo / Ref.</Form.Label>
                        <Form.Control
                            onChange={(e) => setValidacion({ ...validacion, vReferencia: e.target.value })}
                            type="text"
                            size="md"
                            required
                            value={validacion.vReferencia}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModalValidarPago(false) }} variant='primary' >Cerrar</Button>
                    {procesandoModal ? <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Procesando...
                    </Button> :
                        <Button type="submit" onClick={() => onSubmitValidar()} variant='success'>VALIDAR PAGO</Button>}
                </Modal.Footer>

            </Modal>

            {/*Modal para confirmar la eliminacion de la licencia en la orden detalle*/}
            {seguroStateEliminar.isOpen &&
                <Modal
                    show={true}
                    onHide={() => setseguroStateEliminar({ isOpen: false, idLicencia: null, idOrdenDetalle: null })}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    top="top"
                >
                    <Modal.Header>
                        <Modal.Title>&#191;Seguro?</Modal.Title>
                        <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setseguroStateEliminar({ isOpen: false, idLicencia: null, idOrdenDetalle: null }); }} />
                    </Modal.Header>
                    <Modal.Body className="text-dark fs-2">

                        <Row className="mb-3">

                            <Col>
                                &#191; Est&aacute;s <strong>seguro</strong> que deseas eliminar la asignaci&oacute;n de esta licencia a este detalle de la orden?
                            </Col>

                        </Row>


                        <Row>

                            <Col className="col-6">

                                <Button onClick={() => { cancelarLicenciaOrden(seguroStateEliminar.idLicencia, seguroStateEliminar.idOrdenDetalle); }} variant="primary" className="col-12"> SI </Button>

                            </Col>

                            <Col className="col-6">

                                <Button onClick={() => { setseguroStateEliminar({ isOpen: false, idLicencia: null, idOrdenDetalle: null }); }} variant="danger" className="col-12"> NO </Button>

                            </Col>

                        </Row>


                    </Modal.Body>

                    <Modal.Footer>



                    </Modal.Footer>

                </Modal>
            }

            {/*Modal para agregar una licencia a la orden detalle*/}
            {modalStateAgregarLicencia.isOpen &&
                <Modal
                    show={true}
                    onHide={() => setModalStateAgregarLicencia({ isOpen: false, idOrdenDetalle: null })}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    top="top"
                >
                    <Modal.Header>
                        <Modal.Title>Lista de licencias para asignar</Modal.Title>
                        <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setModalStateAgregarLicencia({ isOpen: false, idOrdenDetalle: null }); }} />
                    </Modal.Header>
                    <Modal.Body className="text-dark fs-2">

                        {
                            modalStateAgregarLicencia.isLoading ?
                                <Skeleton /> :
                                <>
                                    {licenciasModal && licenciasModal.length > 0 ?
                                        <>
                                            <Row className="mb-3">

                                                <Col>
                                                    <div className="mt-2"></div>
                                                    <Form.Select aria-label="Selecciona la licencia" style={{ cursor: "pointer" }} onChange={e => setSelectedLicencia(e.target.value)}>
                                                        <option>SELECCIONA EL UUID DE LA LICENCIA</option>
                                                        {licenciasModal && licenciasModal.map((licencia) => (
                                                            <option value={licencia.idLicencia} key={licencia.idLicencia}>
                                                                {licencia.idLicencia} - {licencia.vUUIDLicencia}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>



                                            </Row>


                                            <Row>

                                                <Col className="col-6">

                                                    <Button onClick={() => { guardarLicenciaOrdenDetalle(selectedLicencia, modalStateAgregarLicencia.idOrdenDetalle); }} variant="primary" className="col-12"> Agregar </Button>

                                                </Col>

                                                <Col className="col-6">

                                                    <Button onClick={() => { setModalStateAgregarLicencia({ isOpen: false, idOrdenDetalle: null }); }} variant="danger" className="col-12"> Cancelar </Button>

                                                </Col>


                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row className="mb-3">
                                                <Col>
                                                    <div role="alert" className="alert fs--1 alert-danger alert-dismissible fade show" >
                                                        <strong >El cliente no cuenta con licencias para asignar.</strong>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <button onClick={() => { setModalStateAgregarLicencia({ isOpen: false, idOrdenDetalle: null }); }} className="btn btn-danger col-12">Cancelar</button>
                                                </Col>
                                            </Row>


                                        </>
                                    }
                                </>
                        }



                    </Modal.Body>

                    <Modal.Footer>



                    </Modal.Footer>

                </Modal>
            }

            {showModalConciliar &&
                <ModalConciliarPago
                    showModalConciliar={showModalConciliar}
                    setShowModalConciliar={setShowModalConciliar}
                    mensajeInformativo={mensajeInformativo}
                    setMensajeInformativo={setMensajeInformativo}
                    showMensajeInformativo={showMensajeInformativo}
                    setShowMensajeInformativo={setShowMensajeInformativo}
                    idPago={ID_Pago}
                />
            }
            {
                <ModalSeguimientoLead
                    showModal={showModalSeguimientoLead}
                    setShowModal={setShowModalSeguimientoLead}
                    setMensajeError={setMensajeError}
                    setShowMensajeError={setShowMensajeError}
                    setMensajeInformativo={setMensajeInformativo}
                    setShowMensajeInformativo={setShowMensajeInformativo}
                    idLead={idLead}
                    setIdLead={setIdLead}
                    leadsVinculados={leadsVinculados}
                    setLeadsVinculados={setLeadsVinculados}

                />
            }

            {/* Modal para escribir un comentario de porque se esta cancelando la orden*/}
            <Modal
                show={showCancelarOrdenModal}
                onHide={() => { setShowCancelarOrdenModal(false); setMotivoCancelacionOrden(''); }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">&iquest;C&uacute;al es el motivo para cancelar esta orden ?</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowCancelarOrdenModal(false); setMotivoCancelacionOrden(''); }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={(event) => { setMotivoCancelacionOrden(event.target.value); }}
                            value={motivoCancelacionOrden}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') 
                                        cancelarOrden();
                                }
                            }
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => { setShowCancelarOrdenModal(false); setMotivoCancelacionOrden(''); }}>Regresar</Button>

                    {
                        !loadingEdit ?
                            <Button variant='success' onClick={() => { cancelarOrden();  }}>Guardar Motivo</Button>
                            :
                            <>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Procesando...
                                </Button>
                            </>
                    }

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DetalleOrden;
