import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import FeedCardFooter from './FeedCardFooter';
import FeedCardHeader from './FeedCardHeader';

const FeedCard = ({ comentarios, submitComment, comentario, setComentario, procesandoComentario, idUsuario }) => {
    return (
        <Card>
            <FeedCardFooter
                submitComment={submitComment}
                comentario={comentario}
                setComentario={setComentario}
                procesandoComentario={procesandoComentario}
                idUsuario={idUsuario} />

            <span className="pb-2">
           
                {
                    comentarios.map(
                        (c, i) => {
                            //console.log(idUsuario);
                            return (
                            
                                <FeedCardHeader
                                    key={i}
                                    vNombreUsuario={c.vNombreUsuario}
                                    vComentario={c.vComentario}
                                    idTipoComentario={c.idTipoComentario}
                                    vFecha={c.vFecha}
                                    vHora={c.vHora}
                                    vIP={c.vIP}
                                    idUsuario={Number(c.idUsuario)} />
                           
                        
                            )

                        }
                    )
                }
            </span>

        </Card>
    );
};

FeedCard.propTypes = {
    comentarios: PropTypes.array,
    submitComment: PropTypes.func,
    comentario: PropTypes.string,
    setComentario: PropTypes.func,
    procesandoComentario: PropTypes.bool,
    idUsuario: PropTypes.number
};

export default FeedCard;
